<template>
  <div class="classifier-info page-padding py-7">
    <div
      class="clickable bottom-gap"
      @click="$router.push('/suite/studio/models/classification')"
    >
      <v-icon
        class="inline-middle right-gap"
        color="primary"
      >
        fas fa-chevron-left
      </v-icon>
      <h4 class="inline-middle">
        {{ $t('classifiers.all') }}
      </h4>
    </div>
    <HeaderName
      :item="classifcationModel"
      :editable="false"
      :show-id="false"
    />
    <v-card class="elevation-6 pa-0 top-gap">
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height">
          <v-col>
            {{ $t('classifiers.categories') }}
          </v-col>
          <v-col class="statitics-table-header__cell">
            {{ $t('classifiers.initial') }}
          </v-col>
          <v-col class="statitics-table-header__cell">
            {{ $t('classifiers.training_score') }}
          </v-col>
        </v-row>
      </v-container>
      <div
        v-if="statsLoaded && stats.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px; opacity: 0.5"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="item in stats"
          :key="item.id"
          class="table-row fade-in table-row-height"
        >
          <v-col :ref="`stat${id}`">
            <div
              class="ellipsis"
              :style="{ width: item.width ? `${item.width}px` : '250px' }"
            >
              {{ item.name }}
            </div>
          </v-col>
          <v-col class="statistics-table__cell">
            {{ item.initial }}
          </v-col>
          <v-col class="statistics-table__cell">
            {{ item.initial ? `${(item.score * 100).toFixed(2)}%` : '-' }}
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <div class="top-gap">
      {{ $t('classifiers.confusion') }}
    </div>
    <v-card
      class="elevation-6 pa-0 top-gap"
      style="overflow-x: auto"
    >
      <v-container
        class="pa-0 table-row-height"
        fluid
      >
        <v-row class="table-row table-row__header table-row-height no-wrap-row">
          <v-col class="first_col">
            {{ $t('classifiers.wait_predict') }}
          </v-col>
          <v-col
            v-for="category in confusion"
            :key="category.name"
            class="text-center"
          >
            <div
              class="ellipsis inline-middle"
              :style="{
                'width': category.width ? `${category.width}px` : '50px',
              }"
            >
              {{ category.name }}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div
        v-if="confusionLoaded && confusion.length === 0"
        class="table-row fade-in table-row-height"
        style="text-align: center; padding-top: 15px; opacity: 0.5"
      >
        <i>{{ $t('docTypes.no_results') }}</i>
      </div>
      <v-container
        v-else
        class="pa-0"
        fluid
      >
        <v-row
          v-for="(row, rowIndex) in confusion"
          :key="row.name"
          class="table-row fade-in table-row-height no-wrap-row"
        >
          <v-col class="first_col ellipsis">
            {{ row.name }}
          </v-col>
          <v-col
            v-for="col in row.scores"
            :key="col.name"
            :ref="`${col.name}${rowIndex}`"
            :style="{ 'min-width': `${row.minWidth}px` }"
          >
            <v-tooltip
              v-if="getMisclassifiedFilesPerCell(row.name, col.name).length > 0"
              location="top"
            >
              <template #activator="{ props }">
                <div
                  v-bind="props"
                  :class="['confusion-col text-center', getConfusionColClass(col, row)]"
                  :style="{ 'max-width': getDisplayNumberMaxWidth(row) }"
                >
                  {{ col.score }}
                </div>
              </template>
              <span>
                <div
                  v-for="file in getMisclassifiedFilesPerCell(row.name, col.name)"
                  :key="file"
                >
                  {{ file }}
                </div>
              </span>
            </v-tooltip>
            <template v-else>
              <div
                v-bind="props"
                :class="['confusion-col text-center', getConfusionColClass(col, row)]"
                :style="{ 'max-width': getDisplayNumberMaxWidth(row) }"
              >
                <p>
                  {{ col.score }}
                </p>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';

import HeaderName from '@/components/common/elements/General/HeaderName';

export default {
  name: 'ClassifierInfo',

  components: { HeaderName },

  constants: {
    RENDER_TIMEOUT: 100,
    MENU_TIMEOUT: 300,
  },

  data() {
    return ({
      stats: [],
      confusion: [],
      misclassifiedFiles: {},
      statsLoaded: false,
      confusionLoaded: false,
      classifcationModel: {
        name: '',
      },
    });
  },

  computed: {
    menuOpen() {
      return this.$store.state.menuOpen;
    },
  },

  watch: {
    menuOpen() {
      setTimeout(() => {
        this.getHeaderWidths();
        this.getStatsNameWidth();
      }, this.$options.constants.MENU_TIMEOUT);
    }
  },

  mounted() {
    Promise.all([
      this.getClassificationModel(),
      this.getStats(),
      this.getConfusion(),
    ]);
  },

  methods: {
    getDisplayNumberMaxWidth(category) {
      let maxWidth = 100;
      if (category.width) {
        maxWidth = Math.min(Math.max(category.minWidth, 100), category.width)
      }
      return `${maxWidth}px`
    },

    getWidth(ref, loc) {
      const padding = 24;
      const colArray = this.$refs[ref];
      if (colArray && colArray.length > 0) {
        const renderedColumn = colArray[0].$el;
        if (renderedColumn) {
          const width = renderedColumn.offsetWidth;
          if (width > padding) {
            loc.width = width - padding;
          }
        }
      }
    },

    getStatsNameWidth() {
      if (this.stats.length > 0) {
        this.stats.forEach(item => {
          this.getWidth(`stat${item.id}`, item);
        });
      }
    },

    getHeaderWidths() {
      if (this.confusion.length > 0) {
        const firstRow = this.confusion[0];
        firstRow.scores.forEach((col, i) => {
          this.getWidth(`${col.name}0`, this.confusion[i]);
        });
      }
    },

    getMinWidths(confusion) {
      confusion.forEach(row => {
        const widths = [];
        row.scores.forEach(col => {
          const dummyElement = document.createElement('div');
          dummyElement.style.display = 'inline-block';
          dummyElement.style.visibility = 'hidden';
          dummyElement.style.padding = '12px 20px';
          dummyElement.textContent = col.score;
          document.body.appendChild(dummyElement);
          const width = dummyElement.offsetWidth;
          document.body.removeChild(dummyElement);
          widths.push(width);
        });
        row.minWidth = Math.max(...widths);
      });
    },

    async getStats() {
      try {
        this.stats = await ClassifyModelAPI.getStats({
          modelId: this.classifcationModelId});
        setTimeout(() => {
          this.getStatsNameWidth();
        }, this.$options.constants.RENDER_TIMEOUT);
      } catch (error) {
        this.$router.push({ name: '404Redirect' });
      } finally {
        this.statsLoaded = true;
      }
    },

    async getConfusion() {
      try {
        const response = await ClassifyModelAPI.getConfusion({
          modelId: this.classifcationModelId});
        const { categories, misclassified_files: misclassifiedFiles } = response;
        this.getMinWidths(categories);
        this.confusion = categories;
        setTimeout(() => {
          this.getHeaderWidths();
        }, this.$options.constants.RENDER_TIMEOUT);
        this.misclassifiedFiles = misclassifiedFiles || {};
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.confusionLoaded = true;
      }
    },

    getMisclassifiedFilesPerCell(rowName, colName){
      const row = this.misclassifiedFiles[rowName];
      return row && row[colName] || [];
    },

    getConfusionColClass(col, row) {
      return {
        'confusion-col__number--green': col.name === row.name,
        'confusion-col__number--red': col.score >= 1,
      };
    },

    async getClassificationModel() {
      this.classifcationModel = await ClassifyModelAPI.getModel({modelId: this.classifcationModelId});
    },
  },

  props: {
    classifcationModelId: {
      type: Number,
      required: true,
    }
  },
}
</script>

<style lang="scss" scoped>
.classifier-info {
  .no-wrap-row {
    flex-wrap: nowrap;
    min-width: fit-content;
  }

  .first_col {
    min-width: 170px;
  }

  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }

  .confusion-col {
    padding: 0px;
    min-width: 50px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    &__number {
      &--red,
      &--green {
        border-radius: 8px;
      }

      &--red {
        background-color: #f78b98;
      }

      &--green {
        background-color: #cfe5b5;
      }
    }
  }

  .statitics-table-header__cell {
    display: flex;
    flex-direction: row-reverse;
  }

  .statistics-table__cell {
    display: flex;
    flex-direction: row-reverse;
    background-color: #DDEDFE;
  }

  .model {
    &__blue {
      position: relative;
      z-index: 2;
      text-align: right;
      background-color: #DDEDFE;
      height: 90%;

      &::before {
        content: "";
        position: absolute;
        top: -16px;
        right: -16px;
        bottom: -16px;
        left: -16px;
        background-color: #DDEDFE;
        z-index: -1;
      }

      &::after {
        content: "";
        position: absolute;
        top: calc(100% + 16px);
        left: -16px;
        right: -16px;
        background-color: var(--v-tip-darken1);
        height: 1px;
      }
    }
  }
}
</style>
