<template>
  <div class="text-h2 header-name">
    <div :style="{ opacity: loaded ? 1 : 0 }">
      <div
        v-if="!rename"
        ref="nameContainer"
        class="d-flex primary--text"
      >
        <v-tooltip
          v-if="tooLong"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <span
              class="ellipsis clickable"
              v-bind="props"
              @click="$emit('nameClick')"
            >
              {{ item.name }}
            </span>
          </template>
          <span style="color: white">
            {{ item.name }}
          </span>
        </v-tooltip>
        <span
          v-else
          ref="headerName"
          class="clickable"
          @click="$emit('nameClick')"
        >
          {{ item.name }}
        </span>
        <v-tooltip
          v-if="item.id !== -1 && showId"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-icon
              class="hashtag-icon top-gap-sm"
              style="margin-left: 5px;"
              size="15"
              v-bind="props"
            >
              fas fa-hashtag
            </v-icon>
          </template>
          <span style="color: white">
            id: {{ item.id }}
          </span>
        </v-tooltip>
        <v-tooltip
          v-if="editable && user && authorizedRoles.includes(user.role)"
          color="#423F4F"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-icon
              style="cursor: pointer"
              class="ml-4 mt-2"
              v-bind="props"
              @click="renameItem"
            >
              fas fa-pen
            </v-icon>
          </template>
          <span style="color: white">
            {{ $t("models.edit_name") }}
          </span>
        </v-tooltip>
      </div>
      <div v-else>
        <input
          ref="headerNameField"
          v-model="newName"
          type="text"
          class="header-name-field primary--text text-h2 mr-1"
          :style="{ 'width': `${maxNameWidth - 40}px` }"
          @keydown.enter="saveName"
          @keydown.esc="rename = false"
        >
        <v-tooltip 
          :disabled="!validateButtonDisabled"
          right
        >
          <template #activator="{ props }">
            <span v-bind="props">
              <v-btn
                class="mr-1 mt-3 left-gap-sm"
                variant="text"
                color="primary"
                :disabled="validateButtonDisabled"
                @click="saveName"
              >
                <v-icon>fas fa-check</v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{ validateButtonMessage }}</span>
        </v-tooltip>
        <v-btn
          class="mt-3 left-gap-sm"
          variant="text"
          color="primary"
          @click="$emit('cancelNameEdit'); rename = false;"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';

export default {
  name: 'HeaderName',

  data() {
    return ({
      rename: false,
      newName: '',
      loaded: false,
      authorizedRoles: [
        "orgadmin",
        "sysadmin",
      ],
      windowWidth: window.innerWidth,
    });
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    tooLong() {
      this.windowWidth;
      this.loaded;
      this.$store.getters.menuOpen;
      const headerName = this.$refs.headerName;
      if (headerName) {
        return headerName.offsetWidth > this.maxNameWidth;
      }
      return true;
    },

    maxNameWidth() {
      if (this.maxWidth) {
        return this.maxWidth;
      }
      this.loaded;
      this.windowWidth;
      this.$store.getters.menuOpen;
      const nameContainer = this.$refs.nameContainer;
      if (nameContainer) {
        return nameContainer.offsetWidth - 20;
      }
      return 200;
    },

    validateButtonDisabled() {
      if (this.type === 'dataset' && this.newName.length < 5) return true;
      return false;
    },

    validateButtonMessage() {
      if (this.type === 'dataset' && this.newName.length < 5) {
        return this.$t('dataset.minLengthWarning');
      }
      return '';
    }
  },

  mounted() {
    nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    if (this.item && this.item.name) {
      this.newName = this.item.name;
    }
    this.loaded = true;
  },

  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    saveName() {
      this.$emit('save', this.newName);
      this.loaded = false;
      this.rename = false;
      setTimeout(() => {
        this.loaded = true;
      }, 200);
    },

    renameItem() {
      this.rename = true;
      this.newName = this.item.name;
      setTimeout(() => this.$refs.headerNameField.focus(), 200);
    },
  },

  props: {
    item: {
      required: true,
      type: Object,
    },

    maxWidth: {
      type: Number,
      default: 0,
    },

    editable: {
      type: Boolean,
      default: true,
    },

    showId: {
      type: Boolean,
      default: true,
    },

    type: {
      type: String,
      default: '',
    }
  },

  emits: ['nameClick', 'cancelNameEdit', 'save'],
}
</script>

<style lang="scss" scoped>
.header-name {
  white-space: nowrap;

  .hashtag-icon {
    color: rgb(var(--v-theme-grey-darken2)) !important;
  }

  .hashtag-icon:hover {
    color: rgb(var(--v-theme-primary)) !important;
  }
  
  .header-name-field {
    display: inline-block;
    background-color: #ffffff00;
    padding: 0 !important;
    border: none;

    &:focus {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }

    &:focus-visible {
      outline: none;
      border-bottom: 1px solid rgb(var(--v-theme-primary));
    }
  }
}
</style>
