<template>
  <div class="deleted-datasets top-gap-lg">
    <TableActions
      type="deletedDatasets"
      :number-of-selected="selected.length"
      @delete-click="deleteDialog = true"
      @delete-all-click="deleteAllDialog = true"
      @reinstate-click="handleReinstateButton"
      @filter-change="(filter) => trimmedFilter = filter"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginated.length"
      :total="total"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          {{ $t('models.dataset') }}
        </v-col>
        <v-col cols="5">
          {{ $t("recycleBin.removed_on") }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginated"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col cols="6">
              <ItemName
                :key="item.id"
                :item="item"
                :clickable="false"
              />
            </v-col>
            <v-col
              v-if="config"
              cols="5"
            >
              {{
                $tc(
                  'n_days',
                  getRemovedInDays(item.deleted_on),
                  { count: getRemovedInDays(item.deleted_on) }
                )
              }}
            </v-col>
            <v-col
              v-else
              cols="5"
            />
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('recycleBin.delete_dataset_title')"
      :message="$t('recycleBin.delete_dataset_confirm')"
      @confirm="finalDeleteDataset"
      @close="deleteDialog = false"
    />
    <DeleteDialog
      v-model="deleteAllDialog"
      :title="$t('recycleBin.delete_dataset_all_title')"
      :message="$t('recycleBin.delete_dataset_all_confirm')"
      @confirm="finalDeleteAllDataset"
      @close="deleteAllDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import { DatasetAPI } from '@/API/extract/DatasetAPI';
import table_mixin from '@/mixins/table.js';
import format_mixin from '@/mixins/format.js';
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import TableActions from '@/components/common/elements/Tables/TableActions';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import SortButton from '@/components/common/elements/Tables/SortButton';

export default {
  name: 'DeletedDatasets',

  mixins: [table_mixin, format_mixin],

  components: {
    TableActions,
    TableWithFooter,
    ItemName,
    DeleteDialog,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      deleteAllDialog: false,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {  },

  watch: {
    sortDesc() {
      this.getDeletedDatasets();
    },

    total(total) {
      if (this.trimmedFilter === '') {
        this.$emit('changeTotal', total);
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getDeletedDatasets();
    },

    currentPage(page) {
      this.handleCurrentPageChange(page);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getDeletedDatasets();
      }, 300
    ),
  },

  async created() {
    await this.getDeletedDatasets();
  },

  methods: {
    async handleReinstateButton() {
      await Promise.all(this.selected.map(async ds => {
        try {
          return await http.put(`dataset/restore/${ds.id}/`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.datasets_restored_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
      this.finishDeletion();
    },

    handleCurrentPageChange(page) {
      this.allSelected = false;
      this.paginated.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
      this.getDeletedDatasets((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    async getDeletedDatasets(
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
      try {
        this.loading = true;
        const response = await DatasetAPI.get(
          offset,
          limit,
          this.trimmedFilter || '',
          'deleted',
          this.sortDesc,
          this.external,
          this.external,
          this.external ? this.$route.params.token : null
        );
        this.paginated = response.data;
        this.total = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async finalDeleteAllDataset() {
      try {
        await http.delete(`dataset/empty_bin/`);
        this.paginated = [];
        this.currentPage = 1;
        this.allSelected = false;
        this.deleteAllDialog = false;
        await this.$store.commit(
          'setSuccessMessage', this.$t('models.deleted_dataset_message')
        );
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('changeTotal', 0);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
    },

    async finalDeleteDataset() {
      await Promise.all(this.selected.map(async item => {
        try {
          return await http.delete(`dataset/${item.id}/?soft=false`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.deleted_dataset_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getDeletedDatasets();
      const lastPage = Math.max(1, Math.ceil(this.total / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.handleCurrentPageChange(this.currentPage);
      this.deleteDialog = false;
    },

    getRemovedInDays(removal_date) {
      removal_date = new Date(removal_date);
      if (this.config) {
        const today = new Date();
        const difference = this.datediff(
          today,
          this.addDays(removal_date, this.config.delete_retention_days),
        );
        if (difference < 0) {
          return 0;
        }
        return difference;
      }
      return 0;
    },
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
  },

  emits: ['changeTotal'],
}
</script>
