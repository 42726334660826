<template>
  <div class="orgs-table top-gap-lg">
    <TableActions
      type="organizations"
      :number-of-selected="selected.length"
      :delete-condition="selected.every(s => s.id != user.org_id)"
      @edit-click="editDialog = true"
      @delete-click="deleteDialog = true"
      @create-click="createDialog = true"
      @filter-change="(filter) => trimmedFilter = filter"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginatedOrgs.length"
      :total="paginatedOrgs.length"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton
            v-model="sortDesc"
            style="margin-top: -5px;"
          />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          {{ $t('forms.organization') }}
        </v-col>
        <v-col
          cols="1"
          class="justify-center"
        >
          {{ $t('users.title') }}
        </v-col>
        <v-col cols="4">
          {{ $t('organizations.products') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedOrgs"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col cols="6">
              <ItemName
                style="z-index: 202;"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :clickable="false"
              />
            </v-col>
            <v-col
              cols="1"
              class="justify-center"
            >
              {{ item.n_users }}
            </v-col>
            <v-col cols="4">
              <MaxWidthChip
                v-for="product in item.products"
                :key="product"
                class="flex-shrink-0 mb-0"
                style="text-transform: capitalize !important;"
                :text-array="[product]"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <CreateOrgDialog
      v-model="createDialog"
      @close="createDialog = false"
      @refresh="refreshOrgs"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('organizations.delete')"
      :message="$t('organizations.delete_confirmation')"
      @confirm="deleteOrgs"
      @close="deleteDialog = false"
    />
    <EditOrgDialog
      v-model="editDialog"
      :edited-org="editedOrg"
      @close="editDialog = false"
      @refresh="editRefresh"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import CreateOrgDialog from '@/components/common/elements/Organizations/CreateOrgDialog';
import EditOrgDialog from '@/components/common/elements/Organizations/EditOrgDialog';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";

export default {
  name: 'OrgsTable',

  components: {
    SortButton,
    TableActions,
    TableWithFooter,
    ItemName,
    CreateOrgDialog,
    EditOrgDialog,
    DeleteDialog,
    MaxWidthChip,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      trimmedFilter: '',
      editUser: -1,
      deleteDialog: false,
      editDialog: false,
      createDialog: false,
      totalOrgs: 0,
      allSelected: false,
      loading: false,
      paginatedOrgs: [],
      forceCompute: Math.random(),
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    editedOrg() {
      if (this.selected.length > 0) {
        return this.selected[0];
      }
      return {};
    },

    selected: {
      get() {
        this.forceCompute;
        if (this.paginatedOrgs.length > 0) {
          return this.paginatedOrgs.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  watch: {
    sortDesc() {
      this.getOrgs();
    },

    totalOrgs(total) {
      if (this.trimmedFilter === '') {
        this.$emit('totalChange', total);
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getOrgs();
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedOrgs.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
      this.getOrgs((page - 1) * this.itemsPerPage, this.itemsPerPage);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getOrgs();
      }, 300
    ),
  },

  created() {
    this.getOrgs();
  },

  methods: {
    async deleteOrgs() {
      await Promise.all(this.selected.map(async org => {
        try {
         return await http.delete(`auth/api/v1/organizations/${org.id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getOrgs();
      if (Math.ceil(this.totalOrgs / itemsPerPage) > this.currentPage ) {
        this.currentPage = currentPage;
      } else {
        this.currentPage = 1;
      }
      this.allSelected = false;
      this.selected.forEach(org => {
        org.selected = false;
      });
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('organizations.successDelete')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    editRefresh(newValues) {
      this.editedOrg.name = newValues.name;
      this.editedOrg.products = newValues.products;
      this.editedOrg.selected = false;
      this.allSelected = false;
      this.toggleSelectAll();
    },

    async refreshOrgs() {
      this.paginatedOrgs = [];
      await this.getOrgs();
      this.currentPage = 1;
      this.allSelected = false;
      this.toggleSelectAll();
    },

    async getOrgs(offset = 0, limit = this.itemsPerPage) {
      try {
        this.loading = true;
        const response = await OrgsAPI.getOrgs(
          limit, offset, this.trimmedFilter || '', 'id', this.sortDesc,
        );
        this.paginatedOrgs = response.data;
        this.totalOrgs = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      const paginatedOrgs = this.paginatedOrgs.map(o => {
        o.selected = false;
        return o;
      });
      this.paginatedOrgs = [...paginatedOrgs];
    },

    handleSelect() {
      this.allSelected = this.paginatedOrgs.every(u => u.selected);
      this.forceCompute = Math.random();
    },

    toggleSelectAll() {
      this.paginatedOrgs.forEach(item => {
        item.selected = this.allSelected;
      });
      this.forceCompute = Math.random();
    },
  },

  emits: ['totalChange'],
}
</script>
