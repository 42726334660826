<template>
  <div class="general-settings">
    <div class="top-gap d-flex settings-body">
      <div class="settings-left">
        <h4>{{ $t('settings.platform') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.platform_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <div class="label radio-group-label">
          {{ $t('settings.delete_retention_days') }}
        </div>
        <v-radio-group
          v-model="config.delete_retention_days"
          :disabled="invalidUrl"
          @change="updateConfig"
          inline
        >
          <div
            v-for="(period, i) in retention"
            :key="i"
            class="inline-middle radio-box right-gap-sm"
          >
            <v-radio
              :label="$t(`settings.${period.name}`)"
              :value="period.days"
            />
          </div>
        </v-radio-group>
        <div class="label top-gap-sm">
          {{ $t('settings.login_methods') }}
        </div>
        <div style="margin-bottom: -30px">
          <v-switch
            v-model="useEmailPasswdAuth"
            class="inline-middle"
            color="primary"
            @change="updateLoginSettings"
            inset
          />
          <div class="left-gap-sm inline-middle bottom-gap">
            <h4>{{ $t('settings.use_email_passwd_auth.title') }}</h4>
          </div>
        </div>
        <div
          v-for="provider in paginatedOidcProviders"
          :key="provider.id"
          style="margin-top: -30px"
          @mouseover="hoveredOidc = provider.id"
          @mouseleave="hoveredOidc = null"
        >
          <v-switch
            v-model="provider.is_active"
            style="margin-top: 1px"
            class="inline-middle"
            color="primary"
            :disabled="!provider.is_active && paginatedOidcProviders.filter(p => p.is_active).length === maxOidcProviders"
            @change="toggleCustomProvider(provider)"
            inset
          />
          <div
            class="left-gap-sm inline-middle"
            style="margin-top: -22px"
          >
            <h4
              class="inline-middle ellipsis"
              style="max-width: 450px"
              :style="{opacity: (!provider.is_active && paginatedOidcProviders.filter(p => p.is_active).length === maxOidcProviders) ? 0.5 : 1 }"
            >
              {{ provider.provider_name }}
            </h4>
            <v-tooltip
              v-if="hoveredOidc === provider.id"
              bottom
            >
              <template #activator="{ props }">                  
                <v-icon
                  class="left-gap-sm clickable"
                  size="16"
                  v-bind="props"
                  @click="editedOidcProvider = provider; editOidcDialog = true;"
                >
                  fas fa-pen
                </v-icon>
              </template>
              {{ $t('settings.configure_provider') }}
            </v-tooltip>
            <v-tooltip
              v-if="hoveredOidc === provider.id"
              bottom
            >
              <template #activator="{ props }">                  
                <v-icon
                  class="left-gap-sm clickable"
                  size="16"
                  v-bind="props"
                  @click="selectedOidc = provider.id; deleteOidcDialog = true"
                >
                  fas fa-times
                </v-icon>
              </template>
              {{ $t('delete') }}
            </v-tooltip>
            <div
              class="d-flex"
              style="font-size: 0.8rem"
            >
              <div
                class="ellipsis"
                style="max-width: 500px"
                :style="{opacity: (!provider.is_active && paginatedOidcProviders.filter(p => p.is_active).length === maxOidcProviders) ? 0.5 : 1 }"
              >
                {{ provider.callback }}
              </div>
              <v-tooltip
                v-if="hoveredOidc === provider.id"
                bottom
              >
                <template #activator="{ props }">                  
                  <v-icon
                    class="left-gap-sm clickable"
                    style="margin-top: -4px"
                    size="16"
                    v-bind="props"
                    @click="copyToClipboard(provider.callback)"
                  >
                    fas fa-clipboard
                  </v-icon>
                </template>
                {{ $t('settings.copy_callback') }}
              </v-tooltip>
            </div>
          </div>
        </div>
        <div
          v-if="paginatedOidcProviders.length > maxOidcProviders && paginatedOidcProviders.filter(p => p.is_active).length === maxOidcProviders"
          class="info-box bottom-gap"
        >
          <small>
            <div
              class="inline-middle"
              style="width: 30px"
            >
              <v-icon
                class="info-icon"
                size="16"
              >
                fas fa-info-circle
              </v-icon>
            </div>
            <div
              class="inline-middle"
              style="width: calc(100% - 30px)"
            >
              {{ $t('settings.max_oidc', {number: maxOidcProviders }) }}
            </div>
          </small>
        </div>
        <v-btn
          color="primary"
          class="bottom-gap-sm"
          style="box-shadow: none"
          variant="outlined"
          @click="editedOidcProvider = null; createOidcDialog = true"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-plus
          </v-icon>
          {{ $t('settings.add_custom_login_provider') }}
        </v-btn>
        <div class="label text-field-label top-gap-sm">
          {{ $t('custom_login_url') }}
        </div>
        <div
          class="d-flex align-center"
          style="margin-top: -15px"
        >
          <h4 class="ma-0">
            {{ loginUrl }}
          </h4>
          <v-text-field
            v-model="customLoginSlug"
            class="callback inline-middle left-gap-sm"
            placeholder="organization-name"
            aria-placeholder="organization-name"
            variant="outlined"
            color="primary"
            density="compact"
          />
          <v-btn
            color="primary"
            class="ml-2"
            @click="updateLoginSettings"
            rounded
          >
            {{ $t('save') }}
          </v-btn>
          <v-tooltip bottom>
            <template #activator="{ props }">
              <v-icon
                color="primary"
                class="ml-2"
                v-bind="props"
                @click="updateLoginSettings(); copyToClipboard(loginUrl + customLoginSlug)"
              >
                fas fa-clipboard
              </v-icon>
            </template>
            <span> 
              {{ $t('settings.copy_login_url') }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">

    <div class="top-gap d-flex settings-body">
      <div class="settings-left">
        <h4>{{ $t('settings.processing') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.processing_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <div>
          <div class="label radio-group-label">
            {{ $t('settings.ocr_model.title') }}
          </div>
          <v-radio-group
            v-model="config.ocr_model"
            @change="updateConfig"
            inline
          >
            <div
              v-for="(ocr_m, i) in ocr_models"
              :key="i"
              class="inline-middle radio-box right-gap-sm"
            >
              <v-radio
                :label="ocr_m.name"
                :value="ocr_m.code"
              />
            </div>
          </v-radio-group>
        </div>
        <div>
          <div
            class="left-gap-sm inline-middle"
            style="margin-top: -20px; width: 500px"
          >
            <div style="font-size: 0.8rem">
              {{ $t(`settings.ocr_model.message`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="divider-line settings-body top-gap-sm">

    <div class="top-gap d-flex settings-body">
      <div class="settings-left">
        <h4>{{ $t('settings.callback') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.callback_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <div class="label text-field-label">
          {{ $t('url') }}
        </div>
        <div>
          <v-select
            v-model="protocol"
            class="http-select inline-middle"
            density="compact"
            variant="outlined"
            color="primary"
            :items="protocols"
          />
          <v-text-field
            ref="callback"
            v-model="domain"
            class="inline-middle left-gap-sm mt-0"
            style="width: 300px"
            type="url"
            placeholder="www.example.com"
            clear-icon="fas fa-times"
            variant="outlined"
            color="primary"
            density="compact"
            :error="invalidUrl"
            @keyup.enter="updateConfig"
            clearable
          />
          <div
            v-if="invalidUrl"
            class="error-message left-gap inline-middle"
          >
            {{ $t('settings.invalid_url') }}
          </div>
        </div>
        <div class="label text-field-label">
          {{ $t('token') }}
        </div>
        <v-text-field
          v-model="config.file_upload_callback_token"
          class="inline-middle mt-0"
          style="width: 500px"
          clear-icon="fas fa-times"
          variant="outlined"
          color="primary"
          density="compact"
          @keyup.enter="updateConfig"
          clearable
        />
        <div class="label text-field-label">
          {{ $t('settings.custom_auth_header') }}
        </div>
        <v-text-field
          v-model="config.file_upload_callback_auth_header"
          class="callback inline-middle mt-0"
          style="width: 300px"
          clear-icon="fas fa-times"
          variant="outlined"
          color="primary"
          density="compact"
          @keyup.enter="updateConfig"
          clearable
        />
      </div>
    </div>
    <div
      class="settings-body"
      style="text-align: right"
    >
      <v-btn
        color="primary"
        style="margin-left: -10px"
        @click="updateConfig"
        rounded
      >
        {{ $t('save') }}
      </v-btn>
    </div>

    <hr class="divider-line settings-body top-gap">

    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.api_settings') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.extract_api_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <ApiTokenSettings />
        <div
          v-for="[product, status] of Object.entries(productStatuses)"
          :key="product"
        >
          <div class="label text-field-label top-gap">
            {{ $t(`settings.${product}_api`) }}
            <v-tooltip right>
              <template #activator="{ props }">
                <v-icon
                  size="15"
                  v-bind="props"
                  :color="getIconStatusColor(status)"
                  end
                >
                  fas fa-circle
                </v-icon>
              </template>
              {{ getStatusMessage(status) }}
            </v-tooltip>
          </div>
          <v-btn
            style="box-shadow: none; margin-top: 3px;"
            target="_blank"
            color="primary"
            variant="outlined"
            :href="buildSwaggerUrl(product)"
            :disabled="isAPIButtonDisabled(status)"
            rounded
          >
            <v-icon
              size="17"
              start
            >
              fas fa-link
            </v-icon>
            {{ $t(`settings.swagger.${product}`) }}
          </v-btn>
        </div>
      </div>
    </div>
    <CreateOidcProvider
      v-model="editOidcDialog"
      :edited-oidc-provider="editedOidcProvider"
      @close="editOidcDialog = false"
      @refresh="getOidcProviders"
    />
    <CreateOidcProvider
      v-model="createOidcDialog"
      @close="createOidcDialog = false;"
      @refresh="getOidcProviders"
    />
    <DeleteDialog
      v-model="deleteOidcDialog"
      :title="$t('oidc_providers.delete')"
      :message="$t('oidc_providers.delete_confirmation.message')"
      @confirm="deleteOidcProviders"
      @close="deleteOidcDialog = false"
    />
    <DeleteDialog
      v-model="revokeApiTokenDialog"
      :title="$t('apiToken.revoke')"
      :message="$t('apiToken.revoke_confirmation_message')"
      @confirm="revokeApiToken"
      @close="revokeApiTokenDialog = false"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';

import { OrgsAPI } from '@/API/authenticator/OrgsAPI';
import { ConfigAPI } from '@/API/extract/ConfigAPI';
import { ProductHealthCheck } from '@/utils/ProductHealthCheck';
import CreateOidcProvider from '@/components/extract/elements/Settings/CreateOidcProvider';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ApiTokenSettings from '@/components/common/elements/Settings/ApiTokenSettings';

export default {
  name: 'GeneralSettings',

  components: {
    CreateOidcProvider,
    ApiTokenSettings,
    DeleteDialog,
  },

  data() {
    return ({
      typeName: '',
      config: {
        ocr_model: "DOCTR",
        file_upload_callback: "",
        file_upload_callback_token: "",
        file_upload_callback_auth_header: "",
        delete_retention_days: '',
      },
      ocr_models: [
        {name: 'Tesseract', code: 'TESSERACT'},
        {name: 'Doctr', code: 'DOCTR'},
        {name: 'Google', code: 'GOOGLE'}
      ],
      retention: [
        {name: 'week', days: 7},
        {name: 'month', days: 30},
      ],
      useEmailPasswdAuth: null,
      customLoginSlug: "",
      invalidUrl: false,
      domain: "",
      protocol: "http://",
      protocols: ["http://", "https://"],
      loginUrl: window.location.protocol + "//" + window.location.host + "/suite/login/org/",
      paginatedOidcProviders: [],
      totalOidcProviders: 0,
      maxOidcProviders: 1,
      hoveredOidc: null,
      editOidcDialog: false,
      createOidcDialog: false,
      editedOidcProvider: {},
      deleteOidcDialog: false,
      selectedOidc: null,
      revokeApiTokenDialog: false,
      productStatuses: {},
    })
  },

  computed: {
    url: {
      get: function() {
        if (!this.domain) {
          return null;
        }
        return `${this.protocol}${this.domain}`;
      },
    },

    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  watch: {
    customLoginSlug(newVal) {
      this.customLoginSlug = newVal.replace(/[^a-z0-9-]/g, '').toLowerCase();
    }
  },

  mounted() {
    this.getOidcProviders();
    this.getConfig();
    this.getOrganization();
    const userProducts = this.$store.getters.loggedInUser.products;
    userProducts.push('auth');
    const availableProducts = ProductHealthCheck.productServices.suite.filter(
      e => userProducts.includes(e)
    );
    const serviceStatuses = this.$store.getters.serviceStatus;
    for (const product of availableProducts) {
      this.productStatuses[product] = serviceStatuses[product];
    }
  },

  methods: {
    async copyToClipboard (url, message=this.$t('oidc_providers.callback_url_copied')) {
      await navigator.clipboard.writeText(url);
      await this.$store.commit('setSuccessMessage', message);
      this.$store.commit('setSuccessSnackbar', true);
    },

    async deleteOidcProviders() {
      try {
        await http.delete(`auth/api/v1/openid_providers/${this.selectedOidc}`);
        await this.getOidcProviders();
        await this.$store.commit(
          'setSuccessMessage', this.$t('oidc_providers.successDelete')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.deleteOidcDialog = false;
      }
    },

    async revokeApiToken() {
      try {
        await http.delete(`auth/api/v1/api-token/${this.selectedApiToken}`);
        this.renderingKey++;
        await this.getApiTokens();
        await this.$store.commit(
          'setSuccessMessage', this.$t('apiToken.revoke_success')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.revokeApiTokenDialog = false;
      }
    },


    async getOidcProviders(offset = 0, limit = this.itemsPerPage) {
      try {
        this.loading = true;
        const params = {
          limit,
          offset,
        };
        if (this.user && this.user.role === 'sysadmin' && this.orgFilter != -1) {
          params.org_id = this.orgFilter;
        }
        const response = await http.get(
          `auth/api/v1/openid_providers/`,
          { params }
        );
        this.paginatedOidcProviders = response.data;
        this.totalOidcProviders = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getApiTokens() {
      try {
        this.loading = true;
        const params = {};
        if (this.user && this.user.role === 'sysadmin' && this.orgFilter != -1) {
          params.org_id = this.orgFilter;
        }
        const response = await http.get('auth/api/v1/api-token/');
        this.apiTokens = response.data;
        this.totalApiTokens = parseInt(response.headers['x-total-count'])
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async generateApiToken() {
      try {
        this.loading = true;
        const response = await http.post(
          'auth/api/v1/api-token/', {}
        );
        this.apiTokens.unshift(response.data);
        this.renderingKey++;
        await this.$store.commit(
          'setSuccessMessage', this.$t('apiToken.generated')
        );
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getConfig() {
      try {
        this.config = await ConfigAPI.get();
        if (this.config.file_upload_callback) {
          const { protocol, host, pathname } = new URL(this.config.file_upload_callback);
          this.protocol = `${protocol}//`;
          this.domain = host + pathname;
        }
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      }
    },

    async getOrganization() {
      const user = this.$store.getters.loggedInUser;
      const org = await OrgsAPI.getOneOrg(user.org_id);
      this.useEmailPasswdAuth = org.use_email_passwd_auth;
      this.customLoginSlug = org.custom_login_slug ? org.custom_login_slug : "";
    },

    checkUrl(value) {
      if (value === null) {
        value = '';
      }
      const pattern = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$|^(?:)$|^$/;
      return pattern.test(value);
    },

    async updateLoginSettings() {
      try {
        const user = this.$store.getters.loggedInUser;
        await OrgsAPI.editOrg(user.org_id, {
          "use_email_passwd_auth": this.useEmailPasswdAuth,
          "custom_login_slug": this.customLoginSlug
        });
        this.$store.commit('setSuccessMessage', this.$t('settings.update_success'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      }
    },

    async toggleCustomProvider(provider) {
      setTimeout(async () => {
        try {
          await http.put(`auth/api/v1/openid_providers/${provider.id}/`, {'is_active': provider.is_active});
          this.$store.commit('setSuccessMessage', this.$t('settings.update_success'));
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          this.$store.commit('setErrorMessage', this.$t('oidc_providers.maxActiveAllowedReached'));
          this.$store.commit("setSnackbar", true);
        }
      }, 50);
    },

    async updateConfig() {
      if (!this.checkUrl(this.url)) {
        this.invalidUrl = true;
      } else {
        this.invalidUrl = false;
        this.$refs.callback.blur();
        this.config.file_upload_callback = this.url;
        try {
          await ConfigAPI.update(this.config);
          this.$store.commit('setSuccessMessage', this.$t('settings.update_success'));
          this.$store.commit('setSuccessSnackbar', true);
        } catch (err) {
          console.log(err);
          this.$store.commit('setSnackbar', true);
        }
      }
    },

    buildSwaggerUrl(product) {
      return `${this.$store.getters.config.backends[product]}${product}/docs`;
    },

    getIconStatusColor(status) {
      if (!status.checked) {
        return 'grey';
      }

      if (!status.running) {
        return 'red';
      }

      if ([true, 'running'].includes(status.status)) {
        return 'green';
      }

      return 'orange';
    },

    getStatusMessage(status) {
      if (!status.checked) {
        return this.$t('settings.product_unchecked');
      }

      if (!status.running) {
        return this.$t('settings.product_unavailable');
      }

      if ([true, 'running'].includes(status.status)) {
        return this.$t('settings.product_running');
      }

      return this.$t('settings.product_status_error');
    },

    isAPIButtonDisabled(status) {
      return !status.checked || !status.running || ![true, 'running'].includes(status.status)
    }
  }
}
</script>

<style scoped lang="scss">
.error-message {
  color: rgb(var(--v-theme-error));
  font-size: small;
}

.http-select {
  width: 104px;
  margin-top: -20px !important;
}

.info-box {
  background-color: rgb(var(--v-theme-primary-lighten2));
  border-radius: 6px;
  padding: 6px 17px;
  padding-bottom: 10px;
  width: fit-content;

  .info-icon {
    margin-right: 2px;
    top: -1px;
  }
}
</style>
