<template>
  <div class="business-rules-view">
    <TableActions
      type="businessRules"
      :number-of-selected="selected.length"
      @edit-click="handleEditName"
      @delete-click="deleteDialog = true"
      @create-click="handleCreateButton"
      @filter-change="(filter) => trimmedFilter = filter"
      @filter-enter="handleEnter"
    />
    <TableWithFooter
      :loading="loading"
      :paginated-items-length="paginatedRules.length"
      :total="totalRules"
      :current-page="currentPage"
      :items-per-page="itemsPerPage"
      @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
      @change-page="(page) => currentPage = page"
    >
      <template #header>
        <v-col cols="auto">
          <SortButton v-model="sortDesc" />
          <v-checkbox
            v-model="allSelected"
            class="mt-0"
            @change="toggleSelectAll"
            hide-details
          />
        </v-col>
        <v-col cols="5">
          {{ $t('businessRules.name') }}
        </v-col>
        <v-col cols="2">
          {{ $t('businessRules.type') }}
        </v-col>
      </template>
      <template #body>
        <v-container
          class="pa-0"
          fluid
        >
          <v-row
            v-for="item in paginatedRules"
            :key="item.id"
            class="table-row fade-in table-row-height"
          >
            <v-col cols="auto">
              <v-checkbox
                v-model="item.selected"
                class="left-gap mt-0"
                @change="handleSelect"
                hide-details
              />
            </v-col>
            <v-col
              v-if="item.status === 'incomplete'"
              cols="5"
            >
              <v-tooltip bottom>
                <template #activator="{ props }">
                  <v-icon
                    style="margin-right: 5px;"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                </template>
                {{ $t('businessRules.incomplete') }}
              </v-tooltip>
              <ItemName
                :key="item.id"
                style="width: calc(100% - 21px);"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editingRule === item.id"
                @save-file-name="saveName"
                @name-click="configureBusinessRule({ruleid: item.id}) "
              />
            </v-col>
            <v-col
              v-else-if="item.status === 'error'"
              cols="5"
            >
              <v-tooltip bottom>
                <template #activator="{ props }">
                  <v-icon
                    style="margin-right: 5px;"
                    color="primary"
                    size="16"
                    v-bind="props"
                  >
                    fas fa-exclamation-circle
                  </v-icon>
                </template>
                {{ $t('businessRules.error') }}
              </v-tooltip>
              <ItemName
                :key="item.id"
                style="width: calc(100% - 21px);"
                :item="item"
                @save-file-name="saveName"
                @name-click="configureBusinessRule({ruleid: item.id}) "
              />
            </v-col>
            <v-col
              v-else
              cols="5"
            >
              <ItemName
                :key="item.id"
                style="width: 100%"
                :item="item"
                :editing-allowed="!!(item.selected)"
                :editing="editingRule === item.id"
                @save-file-name="saveName"
                @name-click="configureBusinessRule({ruleid: item.id}) "
              />
            </v-col>
            <v-col cols="2">
              {{ $t(`businessRules.${item.type}`) }}
            </v-col>
            <v-col cols="4">
              <v-tooltip>
                <template #activator="{ props }">
                  <code
                    v-if="['internal', 'subgroup'].includes(item.type) && !loading"
                    v-bind="props"
                    :ref="`rule_logic_${item.id}`"
                    class="code-box ellipsis language-javascript pa-0"
                    lang="javascript"
                    style="word-break: break-all !important; background-color: white;"
                  >
                    {{ parseJsonLogic(item.logic, 1, item.type).slice(1, -1) }}
                  </code>
                </template>
                <div style="max-width: 300px;">
                  <code
                    v-if="['internal', 'subgroup'].includes(item.type) && !loading"
                    :ref="`rule_logic_${item.id}`"
                    class="code-box language-javascript pa-0"
                    lang="javascript"
                    style="word-break: break-all !important;"
                  >
                    {{ parseJsonLogic(item.logic, 1, item.type).slice(1, -1) }}
                  </code>
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </TableWithFooter>
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('businessRules.delete')"
      :message="$t('businessRules.delete_confirmation')"
      @confirm="deleteRules"
      @close="deleteDialog = false"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { http } from '@/plugins/axios';
import '@/assets/recital_highlights.css';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import validation_mixin from "@/mixins/validation.js";
import model_mixin from "@/mixins/model.js";
import business_rule_mixin from '@/mixins/business_rule.js';


export default {
  name: 'BusinessRulesView',

  mixins: [
    validation_mixin,
    model_mixin,
    business_rule_mixin,
  ],

  components: {
    TableActions,
    TableWithFooter,
    DeleteDialog,
    ItemName,
    SortButton
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      node: {'+': [0]},
      allSelected: false,
      deleteDialog: false,
      totalRules: 0,
      trimmedFilter: '',
      loading: false,
      editingRule: -1,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    totalRulesDisplay: {
      get() {
        return this.$store.getters.totalRulesDisplay;
      },
      set(total) {
        this.$store.commit("setTotalRulesDisplay", total);
      }
    },

    selected: {
      get() {
        if (this.paginatedRules.length > 0) {
          return this.paginatedRules.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
  },

  watch: {
    sortDesc(desc) {
      this.getRules(desc, true);
    },

    totalRules(total) {
      if (this.trimmedFilter === '') {
        this.totalRulesDisplay = total;
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getRules(this.sortDesc, true);
    },

    currentPage(page) {
      this.allSelected = false;
      this.paginatedRules.forEach(item => {
        item.selected = this.allSelected;
      });
      this.getRules(
        this.sortDesc, true, (page - 1) * this.itemsPerPage, this.itemsPerPage
      );
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.getRules(this.sortDesc, true);
      }, 300
    ),
  },

  async mounted() {
    this.loading = true;
    await this.getDataPoints();
    await this.getLabelGroups();
    await this.getRules(this.sortDesc);
  },

  methods: {
    async deleteRules() {
      await Promise.all(this.selected.map(async r => {
        try {
          return await http.delete(`system_2/business_rule/${r.id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getRules(this.sortDesc, true);
      const lastPage = Math.max(1, Math.ceil(this.totalRules / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('businessRules.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedRules.forEach(item => {
        item.selected = false;
      });
    },

    async saveName(id, newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(`system_2/business_rule/${id}/`, { name: newName.trim() });
          const rule = this.paginatedRules.find(wf => wf.id === id);
          rule.name = newName;
          rule.selected = false;
          await this.$store.commit(
            'setSuccessMessage', this.$t('businessRules.renamed')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      }
    },

    toggleSelectAll() {
      this.paginatedRules.forEach(item => {
        item.selected = this.allSelected;
      });
      this.paginatedRules = [...this.paginatedRules];
    },

    handleEnter() {
      if (this.paginatedRules.length > 0) {
        this.configureBusinessRule(
          { ruleid: this.paginatedRules[0].id }
        );
      }
    },

    configureBusinessRule(params) {
      this.$router.push({
        name: 'ConfigureBusinessRule',
        params,
      });
    },

    handleEditName() {
      this.editingRule = this.selected[0].id;
    },

    handleCreateButton() {
      this.$router.push({
        name: 'ConfigureBusinessRule',
        params: { ruleid: 'new' },
      });
    },
  }
}
</script>
<style scoped>
.code-box {
  max-width: 100%;
}
</style>
