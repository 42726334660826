import { http } from '@/plugins/axios';

const BASE_PATH = 'dataset/annotation/';

export class AnnotationAPI {
  static get(entryId, type = null) {
    return http
      .get(`${BASE_PATH}${entryId}/`, {
        params: { type }
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static delete(id, type = null) {
    return http
      .delete(`${BASE_PATH}${id}/`, {
        params: { type }
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static postSingle(annotation, type) {
    return http
      .post(BASE_PATH, annotation, { params: { type } })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static postBatch(annotations) {
    return http
      .post(`${BASE_PATH}batch/`, annotations)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static postMultiple(mode, annotations) {
    return http
      .post(`${BASE_PATH}${mode}/`, annotations)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
