import { EmailsDatasetsAPI } from '@/API/classify/EmailsDatasetsAPI.js';
import { store } from '@/plugins/store';
import { i18n } from '@/plugins/i18n/i18n';

const locale = i18n.global;

export async function uploadEmails(datasetId, emailsFiles) {
  const length = emailsFiles.length;
  if (length <= 0) {
    return;
  }

  await startUpload(datasetId, emailsFiles);
}

async function startUpload(datasetId, emailsFiles) {
  await EmailsDatasetsAPI.uploadEmailFile(datasetId, emailsFiles);
  displaySnackbarSuccess(locale.t('emailsDatasets.emails_upload_started'));
}

export function displaySnackbarError(message) {
  store.commit('setErrorMessage', message);
  store.commit('setSnackbar', true);
}

export function displaySnackbarSuccess(message) {
  store.commit('setSuccessMessage', message);
  store.commit('setSuccessSnackbar', true);
}

export const SUPPORTED_EXTENSIONS = ['.eml'];