import { http } from "@/plugins/axios";

export default {
  data() {
    return {
      filesLoading: false,
      unknownPosition: false,
    };
  },

  watch: {
    async currentIndex(index) {
      this.handleCurrentIndex(index);
    },
  },

  computed: {
    currentFile: {
      get() {
        if (this.unknownPosition || this.external) {
          return this.singleFile;
        }
        if (this.selectedId >= 0) {
          const file = this.files.find((file) => file.id === this.selectedId);
          if (file) {
            return file;
          }
        }
        return { id: -1, pages: [] };
      },
    },

    numberOfFiles() {
      return this.files.length;
    },

    totalFilesDisplay: {
      get() {
        return this.$store.getters.totalFilesDisplay;
      },
      set(total) {
        this.$store.commit("setTotalFilesDisplay", total);
      }
    },

    totalFiles: {
      get() {
        return this.$store.getters.totalFiles;
      },
      set(total) {
        this.$store.commit("setTotalFiles", total);
      }
    },

    files: {
      get() {
        return this.$store.getters.files[this.$route.params.id] || [];
      },
      set(newFiles) {
        this.$store.commit("setFiles", {
          typeId: this.$route.params.id,
          files: newFiles,
        });
      },
    },
  },

  methods: {
    dragInsideEnter(e) {
      e.preventDefault();
      this.dragging = true;
      this.inside = true;
    },

    dragEnter(e) {
      e.preventDefault();
      this.dragging = true;
    },

    dragLeave(e) {
      e.preventDefault();
      this.dragging = false;
    },

    getValidFiles(files, allowedExtensions = '') {
      const filteredFiles = [];
      let hasInvalid = false;
      files.forEach((item) => {
        let extension = item.name.split('.');
        extension = extension[extension.length - 1].toLowerCase();
        let acceptedFiles = this.$store.getters.acceptedFiles;
        if (allowedExtensions) {
          acceptedFiles = `${acceptedFiles}, ${allowedExtensions}`;
        }
        let isInvalid = !acceptedFiles.includes(extension);
        if (!isInvalid) {
          filteredFiles.push(item);
        }
        hasInvalid = hasInvalid || isInvalid;
      });

      if (hasInvalid) {
        this.$store.commit(
          'setErrorMessage',
          this.$tc('file_ext_error', files.length == 1 ? 1 : 2)
        );
        this.$store.commit('setSnackbar', true);
        setTimeout(() => {
          this.$store.commit(
            'setErrorMessage',
            this.$t('default_error')
          );
        }, 2050);
      }
      return filteredFiles;
    },

    async dropFile(e, extensions = []) {
      e.preventDefault();
      this.dragging = false;
      this.inside = false;
      if (e.dataTransfer) {
        let files = Object.values(e.dataTransfer.files).map(item => item);
        files = this.getValidFiles(files, extensions.join(', '));
        await this.handleDrop(files);
      }
    },

    async uploadFiles(files) {
      for (let i = 0; i < files.length; i++) {
        await this.startUpload(files[i]);
      }
      this.$refs.uploader.value = '';
    },

    async getMissingFiles() {
      this.filesLoading = true;
      const offset = this.files.findIndex(f => f.type && f.type === 'placeholder');
      if (offset > -1) {
        await this.getFiles(offset, true);
      }
    },

    async findMissingPosition() {
      const index = this.files.findIndex(f => f.id === this.selectedId);
      if (index < 0) {
        await this.getFiles(this.numberOfFiles);
        try {
          this.missingCheck = setTimeout(async () => {
            await this.findMissingPosition();
          }, 400);
        } catch (err) {
          console.log(err);
        }
      } else {
        this.files[index] = this.singleFile;
        this.currentIndex = index;
        this.unknownPosition = false;
      }
    },

    async getFiles(offset = 0, between = false, limit = 20, docTypeId = null) {
      if (offset && offset >= this.totalFiles) {
        return;
      }
      const params = {
        limit,
        offset,
        only_unprocessed: this.production || null,
        sort_desc: true,
      };
      if (this.production) {
        params.doctype_id = docTypeId || this.$route.params.id;
      } else {
        params.document_type_id = this.$route.params.id;
      }
      try {
        this.filesLoading = true;
        const response = await http.get(
          `${this.production && 'production/' || ''}files/`,
          { params }
        );
        if (between) {
          const start = this.files.slice(0, offset);
          const end = this.files.slice(offset + 20);
          this.files = [...start, ...response.data, ...end];
        } else if (offset > 0) {
          this.files = [...this.files, ...response.data];
        } else {
          this.files = response.data;
        }
        this.totalFiles = parseInt(response.headers['x-total-count'], 10);
        this.totalFilesDisplay = this.totalFiles;
      } catch (error) {
        this.$store.commit("setSnackbar", true);
        console.log(error);
      } finally {
        this.filesLoading = false;
      }
    },

    handleSelectChange(id) {
      const index = this.files.findIndex(f => f.id === id);
      if (index > -1 && index != this.currentIndex) {
        this.currentIndex = index;
      }
    },

    selectFile(id) {
      this.selectedId = id;
    },

    async handleCurrentIndex(index) {
      const ordinal = index + 1;
      const offset = index - (index % 20);
      if (ordinal > this.numberOfFiles) {
        if (offset > this.numberOfFiles) {
          const placeholders = [];
          const numberOfPlaceholders = offset - this.numberOfFiles;
          for (let i = 0; i < numberOfPlaceholders; i++) {
            placeholders.push({ id: (i + 1) * -1, type: 'placeholder', })
          }
          this.files = [...this.files, ...placeholders]
        }
        await this.getFiles(offset);
      } else if (this.files[index].type && this.files[index].type === 'placeholder') {
        await this.getFiles(offset, true);
      }
      this.selectFile(this.files[index].id);
    },
  }
};
