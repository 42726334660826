<template>
  <v-dialog
    v-model="openDialog"
    max-width="600"
    @click:outside="$emit('close')"
    @keydown.esc="$emit('close')"
    @keydown.enter="validate()"
  >
    <v-card class="dialog-card">
      <v-icon
        class="close-icon"
        size="17"
        @click="$emit('close')"
      >
        fas fa-times
      </v-icon>

      <v-form ref="form">
        <h2 class="dialog-title mb-3">
          {{ $t('organizations.create') }}
        </h2>
        <div class="label">
          {{ $t('forms.name') }}
        </div>
        <v-text-field
          v-model="form.org.name"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.name"
        />
        <div class="label">
          {{ $t('organizations.products') }}
        </div>
        <div class="d-flex mt-1">
          <v-checkbox
            v-for="product in products"
            :key="product.value"
            v-model="form.org.products"
            style="margin-top: -9px"
            color="primary"
            :value="product.value"
            :label="product.label"
            :disabled="product.disabled"
          />
        </div>
        <hr class="mt-4 bottom-gap">
        <h2 class="dialog-title mb-3">
          {{ $t('organizations.admin') }}
        </h2>
        <div class="label">
          {{ $t('forms.firstName') }}
        </div>
        <v-text-field
          v-model="form.admin.first_name"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.first_name"
        />
        <div class="label">
          {{ $t('forms.lastName') }}
        </div>
        <v-text-field
          v-model="form.admin.last_name"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="commonFieldRules"
          :error-messages="errorsMessages.last_name"
        />
        <div class="label">
          {{ $t('forms.email') }}
        </div>
        <v-text-field
          v-model="form.admin.email"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
          :rules="emailFieldRules"
          :error-messages="errorsMessages.email"
        />
      </v-form>
      <div class="mt-8 d-flex">
        <div class="dialog-button mr-2">
          <v-btn
            style="box-shadow: none"
            variant="outlined"
            @click="$emit('close')"
            block
            rounded
          >
            {{ $t('cancel') }}
          </v-btn>
        </div>
        <div class="dialog-button ml-2">
          <v-btn
            color="primary"
            @click="validate()"
            block
            rounded
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { OrgsAPI } from '@/API/authenticator/OrgsAPI';
import FormRules from '@/utils/classes/FormRules/FormRules';

export default {
  name: 'CreateOrgDialog',

  data() {
    return ({
      commonFieldRules: [FormRules.required],
      emailFieldRules: [FormRules.emailLocal],
      valid: true,
      errorsMessages: {},
      extractDisabled: false,
      searchDisabled: false,
      openDialog: this.modelValue,
      form: {
        org: {
          name: '',
          products: [],
        },
        admin: {
          first_name: '',
          last_name: '',
          email: '',
        },
      },
    });
  },

  watch: {
    openDialog(open) {
      this.$emit('update:modelValue', open);
    },

    modelValue(show) {
      this.openDialog = show;
    },

  },

  mounted() {
    this.setProduct();
  },

  created() {
    this.products = [
      { value: 'extract', label: 'Extract', disabled: this.extractDisabled },
      { value: 'search', label: 'Search', disabled: this.searchDisabled },
      { value: 'classify', label: 'Classify', disabled: this.classifyDisabled },
      { value: 'workflows', label: 'Workflows', disabled: false }
    ];
  },

  methods: {
    setProduct() {
      if (this.$store.getters.product === 'suite') {
        this.extractDisabled = true;
      } else if (this.$store.getters.product === 'search') {
        this.searchDisabled = true;
      } else if (this.$store.getters.product === 'classify') {
        this.classifyDisabled = true;
      }
    },

    resetForm() {
      this.form = {
        org: {
          name: '',
          products: [],
        },
        admin: {
          first_name: '',
          last_name: '',
          email: '',
        },
      };
      this.setProduct();
    },

    validate() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.createOrg();
      }
    },

    async createOrg() {
      this.$store.commit('setLoadingScreen', true);
      try {
        const responseAPI = await OrgsAPI.saveNewOrg(JSON.parse(JSON.stringify(this.form)));
        if (responseAPI.admin) {
          this.$store.commit(
            'setSuccessMessage',
            this.$t('organizations.created')
          );
          this.$store.commit('setSuccessSnackbar', true);
          this.$store.commit('setLoadingScreen', false);
          this.$emit('refresh');
          this.$emit('close');
          this.resetForm();
        }
      } catch(error) {
        console.log(error);
        this.$store.commit('setSnackbar', true);
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },

  props: {
    modelValue: {
      type: Boolean,
      required: true,
    }
  },

  emits: ['close', 'refresh', 'update:modelValue'],
}
</script>

<style lang="scss" scoped>
.dialog-button {
  width: 100%;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
