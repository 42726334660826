<template>
  <div class="classifiers-view top-gap-lg">
    <div style="margin-top: 34px">
      <TableActions
        class="bottom-gap"
        type="classifiers"
        :number-of-selected="selectedClassifiers.length"
        @delete-click="deleteDialog = true"
        @create-click="$emit('createClick')"
      />
      <TableWithFooter
        :loading="loading"
        :paginated-items-length="classifiers.length ?? 0"
        :total="totalClassifiers"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
        @change-page="(page) => currentPage = page"
      >
        <template #header>
          <v-col cols="auto">
            <SortButton v-model="sortDesc" />
            <v-checkbox
              v-model="allSelected"
              class="mt-0"
              hide-details
            />
          </v-col>
          <v-col cols="4">
            {{ $t('classifiers.name') }}
          </v-col>
          <v-col cols="3">
            {{ $t('classifiers.categories') }}
          </v-col>
          <v-col cols="2">
            {{ $t('classifiers.trained') }}
          </v-col>
          <v-col cols="1">
            {{ $t('classifiers.f_score') }}
          </v-col>
          <v-col cols="1">
            {{ $t('classifiers.precision') }}
          </v-col>
        </template>
        <template #body>
          <v-container
            class="pa-0"
            fluid
          >
            <div
              v-for="classifier in classifiers"
              :key="classifier.id"
            >
              <v-row
                class="table-row fade-in table-row-height"
                style="border-bottom: 1px solid #eee"
              >
                <v-col cols="auto">
                  <v-checkbox
                    v-model="classifier.selected"
                    class="left-gap mt-0"
                    @change="classifiers = [...classifiers]"
                    hide-details
                  />
                </v-col>
                <v-col cols="4">
                  <div
                    v-if="classifier.trained === null"
                    class="inline-middle"
                  >
                    <v-tooltip bottom>
                      <template #activator="{ props }">
                        <v-icon
                          class="right-gap-sm"
                          color="primary"
                          size="16"
                          v-bind="props"
                        >
                          fas fa-spinner fa-pulse
                        </v-icon>
                      </template>
                      {{ $t('models.training_in_progress') }}
                    </v-tooltip>
                  </div>
                  <div v-else-if="!classifier.trained">
                    <v-tooltip bottom>
                      <template #activator="{ props }">
                        <v-icon
                          class="right-gap-sm"
                          color="primary"
                          size="16"
                          v-bind="props"
                        >
                          fas fa-exclamation-circle
                        </v-icon>
                      </template>
                      {{ $t('models.training_failed') }}
                    </v-tooltip>
                  </div>
                  <ItemName
                    :key="classifier.id"
                    :width="!classifier.trained ? '80%' : '100%'"
                    :item="classifier"
                    :clickable="!!classifier.trained"
                    @name-click="goToClassifierInfo(classifier)"
                  />
                </v-col>
                <v-col cols="3">
                  <div v-if="classifier.categories.length > 0">
                    <MaxWidthChip
                      class="mt-3"
                      :text-array="[classifier.categories[0].name]"
                      small
                    />
                    <div
                      v-if="classifier.categories.length > 1"
                      class="inline-middle"
                    >
                      <MaxWidthChip
                        class="mt-3"
                        :text-array="[`+${classifier.categories.length - 1}`]"
                        small
                      />
                      <v-tooltip
                        v-if="expanded.length === 0 || expanded[0].id !== classifier.id"
                        color="#423F4F"
                        right
                      >
                        <template #activator="{ props }">
                          <v-icon
                            class="clickable mt-1"
                            size="16"
                            v-bind="props"
                            @click="expanded = [classifier]"
                          >
                            fas fa-chevron-right
                          </v-icon>
                        </template>
                        <span style="color: white">
                          {{ $t('show_all') }}
                        </span>
                      </v-tooltip>
                      <v-icon
                        v-else
                        class="clickable mt-1"
                        size="16"
                        @click="expanded = []"
                      >
                        fas fa-chevron-down
                      </v-icon>
                    </div>
                  </div>
                  <div v-else>
                    <MaxWidthChip
                      class="mt-3"
                      color="#999999"
                      :text-array="[$tc('datatable.header.none', 2)]"
                      small
                    />
                  </div>
                </v-col>
                <v-col cols="2">
                  <span v-if="!classifier.training_result">
                    —
                  </span>
                  <span v-else>
                    {{ formatDate(classifier.training_result.date) }}
                  </span>
                </v-col>
                <v-col cols="1">
                  <span v-if="!classifier.training_result">
                    —
                  </span>
                  <span v-else>
                    {{ Math.round(classifier.training_result.training_score * 100) }}%
                  </span>
                </v-col>
                <v-col cols="1">
                  <span v-if="classifier.precision === null">
                    —
                  </span>
                  <span v-else>
                    {{ Math.round(classifier.precision * 100) }}%
                  </span>
                </v-col>
              </v-row>
              <v-row
                v-if="expanded.length > 0 && expanded[0].id === classifier.id"
                class="table-row"
                style="background-color: rgb(var(--v-theme-grey-darken1))"
              >
                <v-col cols="auto" />
                <v-col cols="4" />
                <v-col
                  cols="6"
                  style="margin-left: 60px"
                >
                  <MaxWidthChip
                    v-for="(category, i) in classifier.categories"
                    :key="i"
                    class="mt-3"
                    :text-array="[category.name]"
                    show-full
                    small
                  />
                </v-col>
              </v-row>
            </div>
          </v-container>
        </template>
      </TableWithFooter>
      <DeleteDialog
        v-model="deleteDialog"
        :title="$t('classifiers.delete')"
        :message="$t('classifiers.delete_confirmation')"
        @confirm="deleteClassifiers"
        @close="deleteDialog = false"
      />
    </div>
  </div>
</template>

<script>

import { ClassifyModelAPI } from '@/API/classify/ClassifyModelAPI';

import TableActions from '@/components/common/elements/Tables/TableActions';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import ItemName from '@/components/common/elements/General/ItemName';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import SortButton from '@/components/common/elements/Tables/SortButton';
import MaxWidthChip from "@/components/common/elements/General/MaxWidthChip";

import format_mixin from '@/mixins/format.js';

export default {
  name: 'ClassifiersView',

  mixins: [format_mixin],

  components: {
    TableActions,
    DeleteDialog,
    ItemName,
    TableWithFooter,
    MaxWidthChip,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: true,
      deleteDialog: false,
      loading: false,
      expanded: [],
      snippetName: '',
      statusCheck: null,
      itemsPerPage,
      currentPage,
      classifiers: [],
    };
  },

  computed: {
    selectedClassifiers() {
      return this.classifiers.filter(item => item.selected);
    },

    allSelected: {
      get() {
        if (this.classifiers.length === 0) {
          return false;
        }

        return this.classifiers.every(item => item.selected);
      },
      set(allSelected) {
        this.classifiers.forEach((classifiers) => {
          classifiers.selected = allSelected;
        });
      }
    },

    totalClassifiers() {
      return this.$store.getters.totalClassifiers;
    },
  },

  watch: {
    sortDesc() {
      this.getClassifiers();
    },

    deleteDialog(on) {
      if (on) {
        clearInterval(this.statusCheck);
      } else {
        this.modelsCountCheck();
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.getClassifiers();
    },

    currentPage() {
      this.getClassifiers();
    },
  },

  mounted() {
    this.modelsCountCheck();
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },

  methods: {
    modelsCountCheck() {
      this.getClassifiers();
      clearInterval(this.statusCheck);
      this.statusCheck = setInterval(() => {
        if (this.$store.getters.totalTrainingClassifiers === 0) {
          clearInterval(this.statusCheck);
        } else {
          this.getClassifiers(false);
        }
      }, 10000);
    },

    async getClassifiers(loading = true) {
      if (loading) {
        this.loading = true;
      }
      try {
        const offset = (this.currentPage - 1) * this.itemsPerPage;
        const [trainedClassifiersResponse, trainingClassifiersResponse] = await Promise.all([
          ClassifyModelAPI.getClassificationModels({
            offset,
            limit: this.itemsPerPage,
            descendingSort: this.sortDesc,
            hasTrained: true
          }),
          /* For training classifiers we are only interested in the total,
           * that's why we set the limit to 1. */
          ClassifyModelAPI.getClassificationModels({
            limit: 1,
            hasTrained: false
          }),
        ]);
        const totalTrainedClassifiers = parseInt(trainedClassifiersResponse.headers['x-total-count'], 10);
        const totalTrainingClassifiers = parseInt(trainingClassifiersResponse.headers['x-total-count'], 10);

        this.classifiers = trainedClassifiersResponse.data;

        this.$store.commit('setTotalClassifiers', totalTrainedClassifiers);
        this.$store.commit('setTotalTrainingClassifiers', totalTrainingClassifiers);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        clearInterval(this.statusCheck);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    goToClassifierInfo(item) {
      if (item.trained) {
        this.$router.push({
          path: `/suite/studio/models/classification/${item.id}`});
      }
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
    },

    async deleteClassifiers() {
      try {
        await Promise.all(this.selectedClassifiers.map(async (classifier) => {
          ClassifyModelAPI.deleteModel({modelName: classifier.name});
        }));
      }
      catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
        return;
      }
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      await this.getClassifiers(true);
      const lastPage = Math.max(1, Math.ceil(this.totalClassifiers / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('classifiers.deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },
  },

  emits: ['createClick'],
}
</script>
