import { http } from '@/plugins/axios';

const BASE_PATH = 'system_2/data_point/';

export class DataPointAPI {
  static get(
    documentTypeId,
    sortDesc = false,
    noAuth = false,
    external = false,
    token = null,
    categories = ['classic', 'custom', 'group_based', 'generative']
  ) {
    return http
      .get(BASE_PATH, {
        params: {
          document_type_id: documentTypeId,
          sort_desc: sortDesc,
          noAuth,
          external,
          token,
          categories,
        },
        paramsSerializer: {
          indexes: null
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static getDataPoint(id) {
    return http
      .get(`${BASE_PATH}${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static post(documentTypeId, name) {
    return http
      .post(`${BASE_PATH}${documentTypeId}/`, { name })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static put(editedDataPoint) {
    return  http
      .put(
        `${BASE_PATH}${editedDataPoint.id}/`,
        editedDataPoint,
      )
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static delete(id) {
    return http
      .delete(`${BASE_PATH}${id}/`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
}
