<template>
  <div class="configure-dp-group">
    <HeaderName
      class="top-input"
      :item="{ name: newName, id: group.id }"
      @save="saveGroupName"
    />
    <ExtractionGroupConfig
      class="top-gap"
      type="extraction_group"
      :group="group"
      @update-extraction-type="type => $emit('updateExtractionType', type)"
      @update-description="description => $emit('updateDescription', description)"
      @update-labels="labels => $emit('updateLabels', labels)"
      @update-primary="index => $emit('updatePrimary', index)"
    />
  </div>
</template>

<script>
import { http } from '@/plugins/axios';
import model_mixin from '@/mixins/model.js';
import HeaderName from '@/components/common/elements/General/HeaderName';
import ExtractionGroupConfig from '@/components/extract/elements/LabelGroups/ExtractionGroupConfig';

export default {
  name: 'ConfigureDPGroup',

  mixins: [
    model_mixin,
  ],

  components: {
    HeaderName,
    ExtractionGroupConfig,
  },

  data() {
    return ({
      newName: '',
    });
  },

  async mounted() {
    this.newName = this.group.name;
  },

  unmounted() {
    this.$store.commit("setLabelGroups", {
      typeId: this.$route.params.id,
      labelGroups: [],
    });
  },

  methods: {
    async saveGroupName(name) {
      try {
        await http.put(
          `system_2/extraction_groups/${this.group.id}`, { name }
        );
        this.newName = name;
        this.$store.commit('setSuccessMessage', this.$t('docTypes.dataPoints.group_updated'));
        this.$store.commit('setSuccessSnackbar', true);
        this.$emit('nameSave');
      } catch (error) {
        console.log(error);
        this.$store.commit('setSnackbar', true);
      }
    },
  },

  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  emits: ['updatePrimary', 'nameSave', 'updateLabels', 'updateExtractionType', 'updateDescription'],
}
</script>

<style lang="scss" scoped>
.configure-dp-group {
  text-align: left;
  width: 100%;
  position: relative;
  padding-bottom: 60px;

  .section {
    margin-top: 15px;
  }

  .top-input {
    max-width: 83%;
  }

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;
    max-width: calc(100% - 170px);

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }
}
</style>
