/* jshint esversion: 9 */
/* jshint expr: true */
/* jshint strict:true */
/* jslint node: true */

import { http } from '@/plugins/axios';

const BASE_PATH = 'classify/api/v1/documents/';

export class ClassifyDocumentsAPI {
  static async get(offset = 0, limit = 20, sortDesc = true, name_filter = '') {
    return http.get(BASE_PATH, {
        params: {
          sort_desc: sortDesc,
          name_filter,
          hitl_required: true,
          auto_created: true,
          offset: offset,
          limit: limit,
        },
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getDocument(id) {
    return http.get(`${BASE_PATH}${id}/`, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async delete(id) {
    return http.delete(`${BASE_PATH}${id}/`, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  static async getPages(id) {
    return http.get(`${BASE_PATH}${id}/pages`, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getPageImage(document_id, page_id) {
    return http.get(`${BASE_PATH}image/${document_id}/pages/${page_id}`, {
        params: {
          thumbnail: false,
        },
        ignoreGlobalCatch: true,
        responseType: 'arraybuffer',
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async getPageThumbnail(document_id, page_id) {
    return http.get(`${BASE_PATH}image/${document_id}/pages/${page_id}`, {
        params: {
          thumbnail: true,
        },
        ignoreGlobalCatch: true,
        responseType: 'arraybuffer',
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }

  static async updateDocument(document_id, is_bundle, data) {
    const path = is_bundle ? `${BASE_PATH}${document_id}/pages` : `${BASE_PATH}${document_id}/`;
    return http.patch(path, data, {
        ignoreGlobalCatch: true,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}
