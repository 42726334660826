<template>
  <div
    class="dataset-table top-gap-lg page-padding"
    @dragenter="dragInsideEnter"
    @dragover="dragInsideEnter"
    @dragleave="inside = false"
    @drop="dropFile"
  >
    <div
      class="bottom-gap d-flex"
      style="justify-content: space-between"
    >
      <div
        class="clickable"
        @click="handleBack"
      >
        <v-icon
          class="inline-middle right-gap"
          color="primary"
        >
          fas fa-chevron-left
        </v-icon>
        <h4 class="text--accent inline-middle">
          {{ $t('models.all_datasets') }}
        </h4>
      </div>
      <div class="d-flex">
        <v-btn
          class="right-gap-sm"
          style="box-shadow: none; margin-top: -2px"
          color="primary"
          variant="outlined"
          :disabled="notEnoughEntries"
          @click="startTraining"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-flask
          </v-icon>
          {{ $t('models.train_new') }}
        </v-btn>
        <div
          v-if="!loading && notEnoughEntries"
          class="info-box inline-middle"
          style="margin-top: -4px"
        >
          <small>
            <div
              class="inline-middle"
              style="width: 30px"
            >
              <v-icon
                class="info-icon"
                size="16"
              >
                fas fa-info-circle
              </v-icon>
            </div>
            <div
              class="inline-middle"
              style="width: calc(100% - 30px)"
            >
              {{ $t('datasets.min_docs', {number: $store.getters.MIN_ENTRIES}) }}
            </div>
          </small>
        </div>
      </div>
    </div>
    <div
      class="d-flex"
      style="justify-content: space-between;"
    >
      <div
        style="max-width: 600px"
        class="right-gap"
      >
        <HeaderName
          type="dataset"
          :item="dataset"
          @save="saveDatasetName"
        />
        <ItemDescription
          :item="dataset"
          @save="saveDatasetDescription"
        />
      </div>
      <div style="margin-top: -10px">
        <v-tabs
          v-model="activeTab"
          color="primary"
          :disabled="false"
        >
          <v-tab
            v-for="(i, key) in tabs"
            :key="i"
          >
            <v-icon
              size="17"
              class="right-gap-sm"
            >
              {{ tabIcons[key] }}
            </v-icon>
            {{ $t(`datasets.${key}`) }}
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <v-window v-model="activeTab">
      <v-window-item
        v-for="i in tabs"
        :key="i"
        style="background-color: #f4f5f9 !important;"
      >
        <div v-if="activeTab === tabs.documents">
          <TableActions
            type="dataset"
            class="top-gap-lg"
            :number-of-selected="selected.length"
            :number-of-valid-files="uploadedCount"
            :edit-condition="selected.length > 0 && selected[0].status === 'ingested'"
            :delete-condition="(!dataset.user_id || dataset.user_id === user.id)"
            :is-annotated="dataset.nb_annotated > 0"
            :is-pre-annotated="dataset.is_preannotated"
            :labels="labels"
            :current-label="labelFilter"
            @edit-click="handleEditName"
            @delete-click="handleDeleteButton"
            @create-click="selectFiles"
            @annotate-click="annotateFile()"
            @pre-annotate-click="selectedModel = -1; preAnnotateOn = true"
            @remove-pre-annotation-click="removePreAnnotationOn = true"
            @copy-click="copyOn = true"
            @filter-change="f => trimmedFilter = f"
            @filter-enter="handleEnter"
            @label-change="l => labelFilter = l"
          />
          <div v-if="!dragging">
            <TableWithFooter
              :loading="loading"
              :paginated-items-length="paginatedFiles.length"
              :total="totalEntries"
              :current-page="currentPage"
              :items-per-page="itemsPerPage"
              @change-items-per-page="(_itemsPerPage) => itemsPerPage = _itemsPerPage"
              @change-page="(page) => currentPage = page"
            >
              <template #header>
                <v-col cols="auto">
                  <SortButton v-model="sortDesc" />
                  <v-checkbox
                    v-model="allSelected"
                    class="mt-0"
                    @change="toggleSelectAll"
                    hide-details
                  />
                </v-col>
                <v-col cols="4">
                  {{ $t('datatable.header.docName') }}
                </v-col>
                <v-col>
                  {{ $t('models.annotation_labels') }}
                </v-col>
                <v-col cols="3">
                  {{ $t('datatable.header.addDate') }}
                </v-col>
              </template>
              <template #body>
                <v-container
                  class="pa-0"
                  fluid
                >
                  <v-row
                    v-for="item in paginatedFiles"
                    :key="item.id"
                    class="table-row fade-in table-row-height"
                  >
                    <v-col cols="auto">
                      <v-checkbox
                        v-model="item.selected"
                        class="left-gap mt-0"
                        @change="handleSelect"
                        hide-details
                      />
                    </v-col>
                    <v-col
                      v-if="item.status === 'ingested'"
                      cols="4"
                    >
                      <ItemName
                        :key="item.id"
                        type="file"
                        :item="item"
                        :editing-allowed="!!(item.selected)"
                        :editing="editingFile === item.id"
                        @save-file-name="saveName"
                        @name-click="annotateFile(item.id)"
                      />
                    </v-col>
                    <v-col
                      v-else-if="item.status === 'error'"
                      cols="4"
                    >
                      <v-tooltip
                        :key="renderKey"
                        bottom
                      >
                        <template #activator="{ props }">
                          <v-icon
                            class="right-gap-sm"
                            color="primary"
                            size="16"
                            v-bind="props"
                          >
                            fas fa-exclamation-circle
                          </v-icon>
                        </template>
                        {{ $t('docTypes.doc_processing_failed') }}
                      </v-tooltip>
                      <ItemName
                        :key="item.id"
                        style="max-width: calc(100% - 26px);"
                        :item="item"
                        :clickable="false"
                      />
                    </v-col>
                    <v-col
                      v-else
                      cols="4"
                    >
                      <v-tooltip bottom>
                        <template #activator="{ props }">
                          <v-icon
                            class="right-gap-sm"
                            color="primary"
                            size="16"
                            v-bind="props"
                          >
                            fas fa-spinner fa-pulse
                          </v-icon>
                          <ItemName
                            :key="item.id"
                            style="max-width: calc(100% - 26px)"
                            :item="item"
                            :clickable="false"
                          />
                        </template>
                        {{ $t('docTypes.being_processed') }}
                      </v-tooltip>
                    </v-col>
                    <v-col
                      class="clickable"
                      style="color: rgb(var(--v-theme-primary))"
                    >
                      <div
                        v-if="item.status === 'ingested'"
                        @click="annotateFile(item.id)"
                      >
                        {{ item.nb_annotated === 0 ? $t('models.annotate') : item.nb_annotated }}
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <small class="gray--text">
                        {{ formatDate(item.uploaded_at) }}
                      </small>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </TableWithFooter>
          </div>
          <v-card
            v-else
            class="upload-card pa-4"
          >
            <div
              class="d-flex flex-column justify-center align-center upload-container"
              :class="{ 'upload-container__active': inside }"
            >
              <div
                class="text-h3"
                :class="{ 'upload-container__active': inside, 'primary--text': !inside }"
              >
                {{ $t('dropFile') }}
              </div>
            </div>
          </v-card>
        </div>
        <div v-if="activeTab === tabs.labels">
          <div class="bottom-gap-sm top-gap-lg radio-box inline-middle">
            <div
              class="inline-middle"
              style="margin-top: -34px"
            >
              <small class="left-gap-sm">
                {{ $t("datasets.advanced") }}
              </small>
            </div>
            <v-switch
              v-model="dataset.advanced"
              class="inline-middle left-gap-sm"
              style="margin-top: -8px"
              color="primary"
              @change="updateDataset(dataset)"
              inset
            />
          </div>
          <ConfigureLabels
            :dataset-id="datasetId"
            :show-all="dataset.advanced"
            @update-labels="l => labels = l"
          />         
        </div>
        <div v-if="activeTab === tabs.configuration">
          <DatasetOptions
            type="edit"
            class="top-gap"
            :dataset="dataset"
            @change="updateDataset"
          />
        </div>
      </v-window-item>
    </v-window>
    <CopyDatasetDialog
      v-model="copyOn"
      :origin-dataset="dataset"
      @close="copyOn = false"
    />
    <DeleteDialog
      v-model="deleteDialog"
      :title="$t('datatable.deleteFile')"
      :message="$t('datatable.deleteConfirmation')"
      @confirm="deleteFile"
      @close="deleteDialog = false"
    />
    <DeleteDialog
      v-model="removePreAnnotationOn"
      :title="$t('models.remove_preannotation')"
      :message="$t('models.remove_preannotation_confirm')"
      @confirm="removePreAnnotation"
      @close="removePreAnnotationOn = false"
    />
    <PreAnnotateDialog
      v-model="preAnnotateOn"
      :title="$t('models.preannotate_dataset')"
      :message="$t('models.preannotate_confirm')"
      @confirm="handlePreannotate"
      @close="preAnnotateOn = false"
    />
    <FileInput
      ref="uploader"
      @change="handleUploadClick"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import { EntryAPI } from '@/API/extract/EntryAPI';

import { http } from '@/plugins/axios';
import format_mixin from '@/mixins/format.js';
import annotation_mixin from '@/mixins/annotation';
import file_mixin from '@/mixins/file.js';
import dataset_mixin from '@/mixins/dataset.js';

import HeaderName from '@/components/common/elements/General/HeaderName';
import ItemDescription from '@/components/common/elements/General/ItemDescription';
import SortButton from '@/components/common/elements/Tables/SortButton';
import TableActions from '@/components/common/elements/Tables/TableActions';
import TableWithFooter from '@/components/common/elements/Tables/TableWithFooter';
import { useTableWithFooter } from '@/composables/useTableWithFooter.js';
import ItemName from '@/components/common/elements/General/ItemName';
import FileInput from '@/components/common/elements/Forms/FileInput';
import DeleteDialog from "@/components/common/elements/Tables/DeleteDialog";
import PreAnnotateDialog from '@/components/extract/elements/Datasets/PreAnnotateDialog';
import DatasetOptions from '@/components/extract/elements/Datasets/DatasetOptions';
import ConfigureLabels from '@/components/extract/elements/Datasets/ConfigureLabels';
import CopyDatasetDialog from '@/components/extract/elements/Datasets/CopyDatasetDialog';


export default {
  name: 'DatasetView',

  mixins: [
    file_mixin,
    format_mixin,
    annotation_mixin,
    dataset_mixin,
  ],

  components: {
    ConfigureLabels,
    CopyDatasetDialog,
    DeleteDialog,
    HeaderName,
    ItemDescription,
    FileInput,
    ItemName,
    PreAnnotateDialog,
    TableActions,
    TableWithFooter,
    DatasetOptions,
    SortButton,
  },

  data() {
    const { itemsPerPage, currentPage } = useTableWithFooter(
      `${this.$route.path}_${this.$options.name}`);

    return {
      sortDesc: false,
      drag: false,
      activeTab: 0,
      tabs: {
        documents: 0,
        labels: 1,
        configuration: 2,
      },
      tabIcons: {
        documents: 'fas fa-file-alt',
        labels: 'fas fa-tags',
        configuration: 'fas fa-cog',
      },
      allSelected: false,
      copyOn: false,
      dataset: {
        id: -1,
        name: '',
        nb_annotated: null,
      },
      deleteDialog: false,
      dragging: false,
      inside: false,
      statusCheck: null,
      loading: false,
      newName: '',
      paginatedFiles: [],
      preAnnotateOn: false,
      removePreAnnotationOn: false,
      renderKey: 10,
      selectedModel: -1,
      totalEntries: 0,
      trimmedFilter: '',
      labelFilter: null,
      uploadedCount: 0,
      labels: [],
      editingFile: -1,
      itemsPerPage,
      currentPage,
    };
  },

  computed: {
    datasetId() {
      return parseInt(this.$route.params.id, 10);
    },

    notEnoughEntries() {
      return this.dataset.status !== 'complete';
    },

    selected: {
      get() {
        if (this.paginatedFiles.length > 0) {
          return this.paginatedFiles.filter(item => item.selected);
        }
        return [];
      },
      set() {
        //pass
      }
    },
    user() {
      return this.$store.getters.loggedInUser;
    },
  },

  watch: {
    sortDesc() {
      this.getEntries(true);
    },

    totalEntries(total) {
      if (this.trimmedFilter === '' && !this.labelFilter) {
        this.uploadedCount = total;
      }
    },

    deleteDialog(on) {
      if (on) {
        clearInterval(this.statusCheck);
      } else {
        this.checkStatus();
      }
    },

    itemsPerPage() {
      this.resetCurrentPage();
      this.checkStatus(true);
    },

    async currentPage() {
      this.allSelected = false;
      this.paginatedFiles.forEach(item => {
        item.selected = this.allSelected;
      });
      this.checkStatus(true);
    },

    labelFilter() {
      this.resetCurrentPage();
      this.checkStatus(true);
    },

    trimmedFilter: _.debounce(
      function() {
        this.resetCurrentPage();
        this.checkStatus(true);
      }, 300
    ),
  },

  mounted() {
    this.loading = true;
    this.dataset.id = this.datasetId;
    const passedName = null;
    if (passedName && passedName !== '') {
      this.dataset.name = passedName;
    }
    this.getDataset(this.datasetId);
    this.paginatedFiles = [];
    this.checkStatus();
    this.getLabels();
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },

  methods: {
    async updateDataset(dataset) {
      try{
        this.$store.commit('setLoadingScreen', true);
        if (this.dataset.force_ocr === false) {
          this.dataset.straighten_pages = false;
        }
        await http.put(
          `dataset/${dataset.id}`,
          {
            ...dataset,
          }
        );
        this.$store.commit('setSuccessMessage', this.$t('dataset.dataset_updated'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    handleBack() {
      this.$router.push('/suite/studio/datasets');
    },

    toggleSelectAll() {
      this.paginatedFiles.forEach(item => {
        item.selected = this.allSelected;
      });
      this.renderKey++;
    },

    resetCurrentPage() {
      this.currentPage = 1;
      this.allSelected = false;
      this.paginatedFiles.forEach(item => {
        item.selected = false;
      });
      this.renderKey++;
    },

    async handlePreannotate(id, version) {
      if (id === 0){
        return;
      }
      this.selectedModel = id;
      this.selectedVersion = version;
      if (this.selected.length > 0) {
        await this.preAnnotateSelected();
      } else {
        await this.preAnnotate();
      }
      this.allSelected = false;
      this.paginatedFiles.forEach(item => {
        item.selected = this.allSelected;
      });
    },

    async preAnnotate() {
      try {
        await http.post(
          `dataset/annotation/${this.dataset.id}/prefill/${this.selectedModel}/`,
          {
            model_version: this.selectedVersion,
          }
        );
        this.dataset.is_preannotated = true;
        this.preAnnotateOn = false;
        this.paginatedFiles = this.paginatedFiles.map((f) => {
          f.status = 'processing';
          return f;
        });
        this.checkStatus();
      } catch (error) {
        console.log(error);
      }
    },

    async preAnnotateSelected() {
      try {
        await http.post(
          `dataset/annotation/prefill_entries/${this.selectedModel}/`,
          {
            entry_ids: this.selected.map(entry => entry.id),
            model_version: this.selectedVersion,
          },
        );
        this.paginatedFiles = this.paginatedFiles.map(entry => {
          if (this.selected.includes(entry)) {
            entry.status = 'processing';
          }
          return entry;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.preAnnotateOn = false;
        this.checkStatus();
      }
    },

    async removePreAnnotation() {
      try {
        this.removePreAnnotationOn = false;
        await http.delete(
          `dataset/annotation/${this.dataset.id}/prefill/`
        );
        this.dataset.is_preannotated = false;
        this.paginatedFiles = this.paginatedFiles.map((f) => {
          f.status = 'processing';
          return f;
        });
        this.checkStatus();
      } catch (error) {
        console.log(error);
      }
    },

    async startTraining() {
      this.$router.push(`/suite/studio/models/extraction/training/${this.dataset.id}`);
    },

    async annotateFile(id = 0) {
      const params = {
        datasetId: this.datasetId,
        labelFilter: this.labelFilter,
        sortDesc: this.sortDesc,
        entryId: id,
      };
      if (this.trimmedFilter !== '' || this.labelFilter) {
        this.trimmedFilter = '';
        this.labelFilter = null;
        await this.getEntries(true);
      }
      this.$store.commit('setAnnotationParams', params);
      this.$router.push({
        name: "Annotation",
        params: {
          id: this.$route.params.id,
        },
      });
    },

    async deleteFile() {
      await Promise.all(this.selected.map(async file => {
        try {
         return await http.delete(`dataset/entry/${file.id}`);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
          return
        }
      }));
      this.finishDeletion();
    },

    async finishDeletion() {
      const { currentPage, itemsPerPage} = this;
      this.getDataset(this.datasetId);
      await this.getEntries(true, (currentPage - 1) * itemsPerPage, itemsPerPage);
      const lastPage = Math.max(1, Math.ceil(this.totalEntries / itemsPerPage));
      this.currentPage = Math.min(currentPage, lastPage);
      this.allSelected = false;
      this.deleteDialog = false;
      await this.$store.commit(
        'setSuccessMessage', this.$t('models.files_deleted_message')
      );
      this.$store.commit('setSuccessSnackbar', true);
    },

    handleSelect() {
      this.allSelected = this.paginatedFiles.every(f => f.selected);
      this.renderKey++;
    },

    async saveName(id, newName) {
      if (newName !== '') {
        this.$store.commit('setLoadingScreen', true);
        try {
          await http.put(`dataset/entry/${id}/rename`, { name: newName });
          const file = this.paginatedFiles.find(f => f.id === id);
          file.name = newName;
          file.selected = false;
          this.$store.commit('setLoadingScreen', false);
          await this.$store.commit(
            'setSuccessMessage', this.$t('datatable.docRenamed')
          );
          this.$store.commit('setSuccessSnackbar', true);
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          this.$store.commit('setLoadingScreen', false);
          console.log(error);
        }
      }
    },

    getProcessingLength(files) {
      return files.filter(f => !['ingested', 'error'].includes(f.status)).length;
    },

    async getEntries(
      force = false,
      offset = (this.currentPage - 1) * this.itemsPerPage,
      limit = this.itemsPerPage)
    {
      if (force) {
        this.loading = true;
      }
      try {
        let oldLength, newLength, processingLength, newProcessingLength
        if (!force) {
          oldLength = this.paginatedFiles.length;
          processingLength = this.getProcessingLength(this.paginatedFiles);
        }
        const response = await EntryAPI.get(
          this.datasetId,
          offset,
          limit,
          this.trimmedFilter || '',
          this.labelFilter,
          null,
          this.sortDesc,
        );
        if (!force) {
          newProcessingLength = this.getProcessingLength(response.data);
          newLength = response.data.length;
        }
        if (force || oldLength !== newLength || processingLength !== newProcessingLength) {
          const files = response.data
            .map(file => {
              const oldFile = this.paginatedFiles.find(f => f.id === file.id);
              if (oldFile && oldFile.selected) {
                file.selected = true;
              } else {
                file.selected = false;
              }
              return file;
            });
          this.paginatedFiles = files;
          this.totalEntries = parseInt(response.headers['x-total-count'], 10);
          this.renderKey++;
        }
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        clearInterval(this.statusCheck);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async handleUploadClick(files) {
      if (files.length > 0) {
        await this.uploadFiles(files);
        this.checkStatus();
      }
    },

    async handleDrop(files) {
      await this.handleUploadClick(files);
    },

    checkStatus(force=false) {
      this.getEntries(
        force, (this.currentPage - 1) * this.itemsPerPage, this.itemsPerPage
      );
      clearInterval(this.statusCheck);
      this.statusCheck = setInterval(() => {
        if (!this.paginatedFiles.some(
          file => ['uploaded', 'processing'].includes(file.status)
        )) {
          clearInterval(this.statusCheck);
          this.getDataset(this.datasetId);
        } else {
          this.getEntries(false);
        }
      }, 3000);
    },

    async startUpload(file) {
      this.$store.commit('setLoadingScreen', true);
      try {
        await EntryAPI.post(this.datasetId, file);
        this.totalEntries++;
        this.renderKey++;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },

    selectFiles() {
      this.$refs.uploader.click()
    },

    handleDeleteButton() {
      this.deleteDialog = true;
    },

    handleEditName() {
      this.editingFile = this.selected[0].id;
    },

    handleEnter() {
      if (this.paginatedFiles.length > 0) {
        this.annotateFile(this.paginatedFiles[0].id);
      }
    },

    async saveDatasetName(newName) {
      if (newName !== '') {
        try {
          this.$store.commit('setLoadingScreen', true);
          await http.put(
            `dataset/${this.datasetId}`,
            { name: newName }
          );
          const dataset = { ...this.dataset, name: newName };
          this.dataset = { ...dataset };
        } catch (error) {
          this.$store.commit('setSnackbar', true);
          console.log(error);
        } finally {
          this.$store.commit('setLoadingScreen', false);
        }
      } else {
        this.$store.commit('setSnackbar', true);
      }
    },

    async saveDatasetDescription(newDescription) {
      try {
        this.$store.commit('setLoadingScreen', true);
        await http.put(
          `dataset/${this.datasetId}`,
          { description: newDescription }
        );
        const dataset = { ...this.dataset, description: newDescription };
        this.dataset = { ...dataset };
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.$store.commit('setLoadingScreen', false);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dataset-table {
  .upload-card {
    width: 100%;
    max-width: 1300px;
    height: 500px;
  }

  .upload-container {
    width: 100%;
    height: 100%;
    border: 2px dashed rgb(var(--v-theme-primary-lighten1));

    &__active {
      background-color: rgb(var(--v-theme-primary-lighten2));
      color: rgb(var(--v-theme-primary-lighten1));
    }
  }

  h4 {
    color: rgb(var(--v-theme-primary));
    font-weight: 500;
  }

  .info-box {
    background-color: rgb(var(--v-theme-primary-lighten2));
    border-radius: 6px;
    padding: 6px 17px;
    padding-bottom: 10px;
    width: fit-content;

    .info-icon {
      margin-right: 2px;
      top: -1px;
    }
  }
}
</style>
