<template>
  <div
    class="doctype-select"
    :style="{ opacity: disabled ? 0.5 : 1 }"
  >
    <div
      class="custom-select noselect"
      @click="toggleSelectOn"
    >
      <div class="doctype-name ellipsis">
        <span :style="{'font-style': numberOfDoctypes ? 'normal' : 'italic' }">
          {{ selectedDoctype.name }}
        </span>
      </div>
      <v-icon
        class="open-icon"
        size="14"
      >
        fas fa-chevron-down
      </v-icon>
      <div
        v-if="selectOn"
        ref="selectPanel"
        class="select-panel"
        tabindex="0"
        @focusout="selectOn = false"
        @scroll="handleScroll"
      >
        <div
          v-for="(doctype, i) in doctypes"
          :ref="`Option${i}`"
          :key="doctype.id"
          class="option ellipsis"
          :value="doctype.id"
          @click="selectedId = doctype.id"
        >
          {{ doctype.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DocTypeAPI } from '@/API/extract/DocTypeAPI';

export default {
  name: 'DocTypeSelect',

  data() {
    return ({
      selectedId: -1,
      selectOn: false,
      doctypes: [],
      loading: false,
      totalDoctypes: 0,
      sortDesc: true,
      initalSelected: null,
    })
  },

  computed: {
    lastPos() {
      return this.numberOfDoctypes - 1;
    },

    numberOfDoctypes() {
      return this.doctypes.length;
    },

    selectedDoctype() {
      if (this.selectedId === 0) {
        if (this.showEvery) {
          return { name: this.$t('docTypes.all') };
        } else {
          return { name: this.$t('docTypes.select') };
        }
      }
      if (this.initialSelected) {
        return this.initialSelected;
      }
      const doctype = this.doctypes.find(d => d.id === this.selectedId);
      if (this.showEvery) {
        return doctype || { name: this.$t('docTypes.all') };
      }
      return doctype || { name: this.$t('docTypes.select') };
    },
  },

  watch: {
    selected(id) {
      this.selectedId = id;
    },

    selectedId(id) {
      if (id !== this.initial) {
        this.initialSelected = null;
      }
      this.$emit('selectedChanged', id);
    },

    doctypes(doctypes) {
      this.$emit('doctypesChanged', doctypes);
    },

    initial(newInitial) {
      if (newInitial) {
        this.setInitial();
      } else {
        this.selectedId = this.selectFirst && this.doctypes.length > 0 ? this.doctypes[0].id : -1;
      }
    },
  },

  async mounted() {
    await this.getDoctypes();
    if (this.initial) {
      await this.setInitial();
    } else {
      this.selectedId = this.selectFirst && this.doctypes.length > 0 ? this.doctypes[0].id : -1;
    }
  },

  methods: {
    async setInitial() {
      const doctype = this.doctypes.find(d => d.id === this.initial);
      if (doctype) {
        this.selectedId = doctype.id;
      } else {
        await this.getInitialDocType();
      }
    },

    async getDoctypes(offset = 0, limit = 20) {
      if (offset && offset >= this.totalDoctypes) {
        return;
      }
      try {
        this.loading = true;
        const response = await DocTypeAPI.get(
          limit,
          offset,
          null,
          null,
          this.filterWithDataPoints,
          this.filterWithGroups,
        );
        if (offset > 0) {
          this.doctypes = [...this.doctypes, ...response.data];
        } else if (this.showEvery) {
          this.doctypes = [
            {
              id: -1,
              name: this.$t('docTypes.all'),
            },
            ...response.data,
          ];
        } else {
          this.doctypes = response.data;
        }
        this.totalDoctypes = parseInt(response.headers['x-total-count'], 10);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getInitialDocType() {
      if (!this.initial || this.initial === -1) {
        return;
      }

      let doctype;

      try {
        let { data: doctypes } = await DocTypeAPI.getDocType(this.initial);
        doctype = doctypes;

        if (!doctype) {
          throw new Error('DocType not found');
        }
        this.initialSelected = doctype;
        this.selectedId = doctype.id;
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
    },

    handleScroll() {
      const TOP_DIFFERENCE = 300;
      const selectPanel = this.$refs.selectPanel;
      const lastOption = this.$refs[`Option${this.lastPos}`];
      if (selectPanel && lastOption) {
        const panelTop = selectPanel.getBoundingClientRect().top;
        const lastTop = lastOption[0].getBoundingClientRect().top;
        const lastTopNormalized = lastTop - panelTop;
        if (
          lastTopNormalized < TOP_DIFFERENCE &&
          !this.$store.getters.loadingScreen &&
          !this.loading
        ) {
          this.getDoctypes(this.doctypes.length);
        }
      }
    },

    toggleSelectOn() {
      if (this.disabled) {
        return;
      }
      this.selectOn = !this.selectOn;
      if (this.selectOn) {
        setTimeout(() => {
          this.$refs.selectPanel.focus();
        }, 50);
      }
    }
  },

  props: {
    initial: {
      type: Number,
      default: 0,
    },

    showEvery: {
      type: Boolean,
      default: false,
    },

    hasDataPointsFilter: {
      type: Boolean,
      default: false,
    },

    selectFirst: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    filterWithDataPoints: {
      type: Boolean,
      default: false,
    },

    filterWithGroups: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['selectedChanged', 'doctypesChanged'],
}
</script>

<style lang="scss" scoped>
.doctype-select {
  .custom-select {
    width: 300px;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #C8C8C8;
    background-color: white;
    padding: 8px;
    padding-right: 30px;
    font-size: 0.9rem;
    position: relative;

    .doctype-name {
      max-width: 250px;
    }
    
    .open-icon {
      position: absolute;
      right: 5px;
      top: 11px;
      color: rgb(var(--v-theme-dark));
      opacity: var(--v-medium-emphasis-opacity);
    }

    .select-panel {
      width: 300px;
      max-height: 280px;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 999;
      background-color: white;
      border-radius: 4px;
      border: solid 1px #C8C8C8;
    }

    .select-panel:focus, input:focus{
      outline: none;
    }

    .select-panel .option {
      height: 40px;
      padding: 8px;
      background-color: white !important;
      box-shadow: 0 0 10px 100px white inset;
      color: black !important;
    }
  }
}
</style>
