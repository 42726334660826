<template>
  <div class="page-padding h-100 py-7">
    <BreadcrumbComponent />
    <InitialSearchUpload
      v-if="shouldUpload"
      @upload-complete="getFolders"
    />
    <LoadingIcon v-else-if="loadingFolders" />
    <div v-else>
      <v-card class="folder-navigation inline-top right-gap-lg position-relative">
        <h4 class="bottom-gap">
          {{ $t('search.filter_by_folders') }}
        </h4>
        <v-btn
          v-if="stores.length === 0"
          class="bottom-gap-sm"
          style="box-shadow: none"
          color="primary"
          variant="outlined"
          @click="addingStore = true"
          rounded
        >
          <v-icon
            size="17"
            start
          >
            fas fa-plus
          </v-icon>
          Add store
        </v-btn>
        <v-tooltip
          v-if="syncCondition"
          top
        >
          <template #activator="{ props }">
            <v-icon
              v-bind="props"
              class="clickable position-absolute ml-1 my-1"
              style="top: 28px; right: 28px;"
              color="primary"
              @click="syncStore"
            >
              {{ syncing ? 'fa-pulse' : '' }} mdi mdi-autorenew
            </v-icon>
          </template>
          {{ $t('search.home.sync_folder') }}
        </v-tooltip>
        <div
          class="top-gap pb-5"
          style="overflow-x: auto"
        >
          <small
            v-if="!loadingFolders && folders.length === 0"
            class="top-gap-sm font-italic"
            style="color: #888;"
          >
            {{ $t('search.home.no_folders_message') || $t('search.home.no_folders_found') }}
          </small>
          <FolderNode
            v-for="folder in folders"
            :key="folder.id"
            :ref="`folderNode-${folder.id}`"
            type="browse"
            :folder="folder"
            @folder-deleted="(id, _) => updateFolderList(id)"
            @refresh-folders="getFolders"
          />
          <div
            v-if="!addingNewFolder"
            class="clickable d-flex align-bottom"
            style="margin-top: 5px"
            @click="addingNewFolder = true"
          >
            <v-icon
              color="primary"
              class="mr-2"
            >
              fas fa-folder-plus
            </v-icon>
            <ItemName
              class="noselect"
              :item="{ name: $t('search.home.new_folder') }"
              :show-id="false"
              :fade-in-slow="false"
            />
          </div>
          <div
            v-else
            class="text-left mb-1 position-relative"
          >
            <input
              ref="newFolderInput"
              v-model="newFolderName"
              class="simple-input mb-0"
              style="width: 80%;"
              :placeholder="$t('search.home.folder_name')"
              @keyup.enter="createFolder"
              @keyup.escape="addingNewFolder = false"
            >
            <v-icon
              class="right-gap-sm position-absolute clickable"
              style="top: 50%; transform: translateY(-50%);"
              size="13"
              color="primary"
              @click="createFolder"
            >
              fas fa-check
            </v-icon>
          </div>
        </div>
      </v-card>
      <SearchFilesTable
        ref="filesTable"
        class="file-list inline-top"
        :loading="loadingFolders"
        @select-files="selectFiles"
        @update-file-number="updateFileNumber"
        @sync="syncStore"
        @refresh-folders="getFolders"
      />
      <FileInput
        ref="uploader"
        type="searchFiles"
        @change="uploadRefresh"
      />
    </div>
    <v-dialog
      v-model="addingStore"
      max-width="600"
    >
      <v-card class="dialog-card">
        <h2 class="dialog-title mb-8">
          Add synced store
        </h2>
        <div class="label">
          Bucket name
        </div>
        <v-text-field
          v-model="store.bucket_name"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
        />
        <div class="label">
          URL
        </div>
        <v-text-field
          v-model="store.bucket_url"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
        />
        <div class="label">
          Access key
        </div>
        <v-text-field
          v-model="store.access_key"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
        />
        <div class="label">
          Secret key
        </div>
        <v-text-field
          v-model="store.secret_key"
          style="margin-top: 5px"
          variant="outlined"
          color="primary"
          density="compact"
        />
        <div class="mt-8 d-flex">
          <div class="dialog-button mr-2">
            <v-btn
              style="box-shadow: none"
              variant="outlined"
              @click="addingStore = false"
              block
              rounded
            >
              {{ $t('cancel') }}
            </v-btn>
          </div>
          <div class="dialog-button ml-2">
            <v-btn
              color="primary"
              @click="addStore"
              block
              rounded
            >
              {{ $t('save') }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import { FolderAPI } from '@/API/search/FolderAPI';
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';
import { SearchFileAPI } from '@/API/search/SearchFileAPI';
import { StoreAPI } from '@/API/search/StoreAPI';

import BreadcrumbComponent from '@/components/common/elements/Navigation/BreadcrumbComponent';
import InitialSearchUpload from "@/components/search/views/Home/InitialSearchUpload";
import FileInput from '@/components/common/elements/Forms/FileInput';
import SearchFilesTable from '@/components/search/elements/Docs/SearchFilesTable.vue';
import FolderNode from '@/components/search/elements/Home/FolderNode.vue';
import LoadingIcon from '@/components/search/elements/common/LoadingIcon.vue';
import ItemName from '@/components/common/elements/General/ItemName';

import { setSnackbarMessage } from '@/utils/classes/Utils';


export default {
  name: 'SearchDocView',

  components: {
    BreadcrumbComponent,
    InitialSearchUpload,
    FileInput,
    SearchFilesTable,
    FolderNode,
    LoadingIcon,
    ItemName,
  },

  data() {
    return {
      folders: [],
      loadingFolders: false,
      addingNewFolder: false,
      newFolderName: '',
      stores: [],
      syncing: false,
      addingStore: false,
      root: null,
      store: {
        bucket_name: '',
        access_key: '',
        secret_key: '',
        bucket_url: '',
      }
    };
  },

  computed: {
    syncCondition() {
      return (
        this.stores.length > 0
          && this.allSearchFolders.some(folder => folder.synced)
      );
    },

    allSearchFolders: {
      get() {
        return this.$store.getters.allSearchFolders;
      },
      set(payload) {
        this.$store.commit('setAllSearchFolders', payload);
      },
    },

    searchFolders: {
      get() {
        return this.$store.getters.searchFolders;
      },
      set(folders) {
        this.$store.commit('setSearchFolders', folders);
      }
    },

    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },

    rootId: {
      get() {
        return this.$store.getters.rootId;
      },
      set(payload) {
        this.$store.commit('setRootId', payload);
      },
    },

    searchFolder: {
      get() {
        return this.$store.getters.searchFolder;
      },
      set(payload) {
        this.$store.commit('setSearchFolder', payload);
      },
    },

    browseFolder: {
      get() {
        return this.$store.getters.browseFolder;
      },
      set(val) {
        this.$store.commit('setBrowseFolder', val);
      },
    },
    
    searchOrg() {
      return this.$store.getters.searchOrg;
    },

    searchTotalFiles: {
      get() {
        return this.$store.getters.searchTotalFiles;
      },
      set(val) {
        this.$store.commit('setSearchTotalFiles', val);
      },
    },

    shouldUpload() {
      return (
        !this.loadingFolders
        && ['orgadmin', 'sysadmin'].includes(this.loggedInUser.role)
        && this.searchTotalFiles === 0
        && this.folders.length === 0
      );
    },
  },

  watch: {
    addingNewFolder(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.newFolderInput.focus();
        });
      }
    },
  },

  async created() {
    if (this.searchOrg === -1) {
      this.$store.commit('setSearchOrg', this.loggedInUser.org_id);
    }
    this.$store.commit('setBreadcrumb',
      [
        { title: this.$t('breadcrumb.home') },
        { title: this.$t('search.documents_capitalized') },
      ]
    );
  },

  unmounted() {
    clearInterval(this.statusCheck);
  },
  
  async mounted() {
    this.loadingFolders = true;
    this.rootId = await SearchConfigAPI.getRoot(this.$store.getters.searchOrg);
    this.root = await FolderAPI.getFolder(this.rootId);
    const [totalFiles] = await Promise.all([
      this.getFilesCount(this.rootId),
      this.getFolders(),
    ]);
    this.searchTotalFiles = totalFiles;
  },

  methods: {
    async addStore() {
      try {
        const addedStore = await FolderAPI.addStore(this.store);
        this.addingStore = false;
        await this.syncStore(addedStore);
        setTimeout(() => {
          this.getFolders();
        }, 1000);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      }
    },

    async syncStore(currentStore = null) {
      try {
        this.syncing = true;
        const folderNames = this.allSearchFolders.map(f => f.name);
        if (!currentStore) {
          currentStore = this.stores.find(s => folderNames.includes(s.bucket_name));
        }
        await FolderAPI.syncStore(currentStore.id)
        this.$store.commit('setSuccessMessage', this.$t('search.syncing'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (error) {
        this.$store.commit('setSnackbar', true);
        console.log(error);
      } finally {
        this.syncing = false;
      }
    },

    async getStores() {
      try {
        this.stores = await StoreAPI.getStores();
      } catch (error) {
        console.log(error);
      }
    },

    async getFilesCount(id) {
      try {
        const folder = await FolderAPI.getFolder(id);
        return folder.nb_processed_files;
      } catch (err) {
        setSnackbarMessage(err.response.data.detail, { id });
      }
    },

    async getFolders(id = null) {
      try {
        let folders = await FolderAPI.getFolders(id);
        folders = folders.map(f => {
          f.open = false;
          f.folders = [];
          f.selected = false;
          return f;
        });
        this.searchFolders = _.cloneDeep(folders.filter(f => f.parent_folder_id === this.rootId));
        this.allSearchFolders = folders.map(f => {
          const existing = this.allSearchFolders.find(af => af.id === f.id);
          if (existing) {
            return {
              ...f,
              ...existing,
            };
          }
          return f;
        });
        this.folders = this.allSearchFolders.filter(f => f.parent_folder_id === this.rootId);
        this.getStores();
      } catch (err) {
        let message;
        if (err.response) {
          message = err.response.data.detail;
        } else {
          message = err;
        }
        setSnackbarMessage(message);
        clearInterval(this.statusCheck);
      } finally {
        this.loadingFolders = false;
      }
    },

    async createFolder() {
      try {
        if (!this.newFolderName.trim()) {
          return;
        }
        await FolderAPI.createFolder({
          name: this.newFolderName.trim(),
          parent_id: this.rootId,
        });
        await this.getFolders(this.rootId);
      } catch (err) {
        setSnackbarMessage(err.response.data.detail);
        console.log(err);
        return;
      } finally {
        this.addingNewFolder = false;
        this.newFolderName = '';
      }
    },

    selectFiles() {
      this.$refs.uploader.click();
    },

    getParentIds(id, parentList) {
      if (id === this.rootId) {
        return parentList;
      }
      const folder = this.allSearchFolders.find(f => f.id === id);
      return this.getParentIds(folder.parent_folder_id, [folder.id, ...parentList]);
    },

    getParentNodes(parentIds, parentNodes) {
      const next = parentIds.shift();
      const lastIndex = parentNodes.length - 1;
      const nextNodeList = [
        ...parentNodes,
        parentNodes[lastIndex].$refs[`folderNode-${next}`][0],
      ];
      if (parentIds.length === 0) {
        // Exclude the root node
        return nextNodeList.slice(1);
      }
      return this.getParentNodes(parentIds, nextNodeList);
    },

    updateFileNumber(nbFiles, id = this.browseFolder && this.browseFolder.id) {
      if (id) {
        const parentIds = this.getParentIds(id, []);
        const parents = this.getParentNodes(parentIds, [this]);
        parents.forEach(folder => folder.updateFileNumber(nbFiles))
      } else {
        this.checkNewFolders();
      }
    },

    async uploadRefresh(files) {
      await this.uploadFiles(files);
      this.updateFileNumber(files.length);
      this.$refs.filesTable.checkStatus(true);
    },

    checkNewFolders() {
      if (!this.root) {
        return
      }
      this.getFolders();
      clearInterval(this.statusCheck);
      this.statusCheck = setInterval(() => {
        if (this.folders.length === this.root.nb_children_folders) {
          clearInterval(this.statusCheck);
        } else {
          this.getFolders();
        }
      }, 3000);
    },

    async uploadFiles(files) {
      for (let i = 0; i < files.length; i++) {
        await this.startUpload(files[i]);
      }
      this.$refs.uploader.value = '';
    },

    async startUpload(file) {
      try {
        let folderId = this.rootId;
        if (this.browseFolder) {
          folderId = this.browseFolder.id;
        }
        await SearchFileAPI.post(file, folderId);
      } catch (error) {
        console.log(error);
        this.updateFileNumber(-1);
      }
    },

    updateFolderList(id) {
      this.allSearchFolders = this.allSearchFolders.filter(f => f.id !== id || f.parent_folder_id !== id);
      this.folders = this.folders.filter(f => f.parent_folder_id === this.rootId);
      this.checkNewFolders();
    },
  }
};
</script>
<style lang="scss" scoped>
.folder-navigation {
  min-height: calc(100vh - 122px);
  width: 300px;
  overflow-y: auto;
}

.file-list {
  width: calc(100% - 340px);
}

.simple-input {
  width: 100%;
  padding: 5px 0;
  margin-bottom: 10px;
  border-radius: 0 !important;
  border-bottom: solid 1px #aaa;
  border-radius: 5px;
  outline: none;
  font-size: small;

  &:focus, &:hover {
    border-color: rgb(var(--v-theme-primary));
  }

  transition: border-color 0.2s;
}

.dialog-button {
  width: 100%;
}
</style>
