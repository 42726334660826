<template>
  <div class="settings-view page-padding py-7">
    <BreadcrumbComponent />
    <OrgSelect
      v-if="user && user.role === 'sysadmin'"
      class="right-gap inline-middle table-action"
      :selected="selectedOrg"
      @selected-changed="id => selectedOrg = id"
    />
    <ProfileSettings />
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search.metadata.title') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search.metadata.explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchMetadataSettings
          :org-id="selectedOrg"
          :editing-allowed="editingAllowed"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_llm_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_llm_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchLLMSettings
          :config="config"
          :options="configOptions"
          :loading="loading"
          :editing-allowed="editingAllowed"
          @update="updateConfig"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchEnhancementSettings
          :config="config"
          :loading="loading"
          :editing-allowed="editingAllowed"
          @update="updateConfig"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_results_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_results_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchResultSettings
          :config="config"
          :loading="loading"
          :editing-allowed="editingAllowed"
          @update="updateConfig"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>{{ $t('settings.search_other_config') }}</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_other_config_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <SearchOtherSettings
          :config="config"
          :loading="loading"
          :editing-allowed="editingAllowed"
          @update="updateConfig"
        />
      </div>
    </div>
    <hr class="divider-line settings-body top-gap">
    <div class="top-gap settings-body d-flex">
      <div class="settings-left">
        <h4>API</h4>
        <div style="font-size: 0.8rem">
          {{ $t('settings.search_api_explanation') }}
        </div>
      </div>
      <div class="settings-right">
        <ApiTokenSettings
          app="search"
          :editing-allowed="editingAllowed"
        />
        <div
          v-for="[product, status] of Object.entries(productStatuses)"
          :key="product"
        >
          <div class="label text-field-label top-gap">
            {{ $t(`settings.${product}_api`) }}
            <v-tooltip right>
              <template #activator="{ props }">
                <v-icon
                  size="15"
                  v-bind="props"
                  :color="getIconStatusColor(status)"
                  end
                >
                  fas fa-circle
                </v-icon>
              </template>
              {{ getStatusMessage(status) }}
            </v-tooltip>
          </div>
          <v-btn
            style="box-shadow: none; margin-top: 3px;"
            target="_blank"
            color="primary"
            variant="outlined"
            :href="buildSwaggerUrl(product)"
            :disabled="isAPIButtonDisabled(status)"
            rounded
          >
            <v-icon
              size="17"
              start
            >
              fas fa-link
            </v-icon>
            {{ $t(`settings.swagger.${product}`) }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SearchConfigAPI } from '@/API/search/SearchConfigAPI';

import BreadcrumbComponent from "@/components/common/elements/Navigation/BreadcrumbComponent";
import ProfileSettings from '@/components/common/elements/Settings/ProfileSettings';
import ApiTokenSettings from '@/components/common/elements/Settings/ApiTokenSettings';
import OrgSelect from "@/components/common/elements/Organizations/OrgSelect";
import { ProductHealthCheck } from "@/utils/ProductHealthCheck";
import SearchEnhancementSettings from '@/components/search/elements/Settings/SearchEnhancementSettings';
import SearchResultSettings from '@/components/search/elements/Settings/SearchResultSettings';
import SearchLLMSettings from '@/components/search/elements/Settings/SearchLLMSettings';
import SearchOtherSettings from '@/components/search/elements/Settings/SearchOtherSettings';
import SearchMetadataSettings from '@/components/search/elements/Settings/SearchMetadataSettings';

export default {
  name: 'SearchSettingsView',

  components: {
    BreadcrumbComponent,
    ProfileSettings,
    ApiTokenSettings,
    OrgSelect,
    SearchEnhancementSettings,
    SearchResultSettings,
    SearchLLMSettings,
    SearchOtherSettings,
    SearchMetadataSettings,
  },

  data() {
    return ({
      loading: false,
      selectedOrg: null,
      config: {
        length_chunk: 0,
        detect_titles: false,
        top_chunk: 0,
        coef_ranker_chunk: 0,
        use_synonyms: false,
        boost_content_chunk: 0,
        top_doc: 0,
        coef_ranker_doc: 0,
        boost_content_doc: 0,
        s3_fallback: false,
        llm_provider: null,
        llm_name: null,
        llm_api_key: null,
        llm_active: false,
      },
      configOptions: {
        llm_provider: [],
        llm_name: null,
      },
      productStatuses: {},
    });
  },

  computed: {
    user() {
      if (this.$store.getters.loggedInUser) {
        return this.$store.getters.loggedInUser;
      }
      return null;
    },

    editingAllowed() {
      if (this.user.role === "sysadmin") {
        return false;
      }
      return true;
    },
  },

  watch: {
    async selectedOrg() {
      this.$store.commit('setLoadingScreen', true);
      await this.getSearchConfig();
      this.$store.commit('setLoadingScreen', false);
    }
  },

  async created() {
    if (this.user && this.user.role === 'sysadmin') {
      this.selectedOrg = this.user.org_id;
    }
    this.setBreadcrumb();
    this.$store.commit('setLoadingScreen', true);
    await Promise.all([
      this.getSearchConfig(),
      this.getSearchConfigOptions()
    ]);
    this.$store.commit('setLoadingScreen', false);
    const serviceStatus = this.$store.getters.serviceStatus;
    for (const product of ProductHealthCheck.productServices.search) {
        this.productStatuses[product] = serviceStatus[product];
    }
  },

  methods: {
    async getSearchConfig() {
      try {
        const config = await SearchConfigAPI.get(this.selectedOrg);
        config.llm_provider = 'OpenAI';
        config.llm_api_key = config.llm_api_key_decrypted;
        this.config = config;
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      }
    },

    async getSearchConfigOptions() {
      try {
        this.configOptions = await SearchConfigAPI.getOptions();
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      }
    },

    async updateConfig() {
      this.loading = true;
      this.$store.commit('setLoadingScreen', true);
      try {
        await SearchConfigAPI.update(this.config);
        this.$store.commit('setSuccessMessage', this.$t('settings.update_success'));
        this.$store.commit('setSuccessSnackbar', true);
      } catch (err) {
        console.log(err);
        this.$store.commit('setSnackbar', true);
      } finally {
        this.loading = false;
        this.$store.commit('setLoadingScreen', false);
      }
    },
  
    setBreadcrumb() {
      this.$store.commit('setBreadcrumb',
        [
          { title: this.$t('breadcrumb.home'), href: {name: 'SearchHome'} },
          { title: this.$t('menu.settings.title')},
        ]
      );
    },

    buildSwaggerUrl(product) {
      return `${this.$store.getters.config.backends[product]}${product}/docs`;
    },

    getIconStatusColor(status) {
      if (!status.checked) {
        return 'grey';
      }

      if (!status.running) {
        return 'red';
      }

      if ([true, 'running'].includes(status.status)) {
        return 'green';
      }

      return 'orange';
    },

    getStatusMessage(status) {
      if (!status.checked) {
        return this.$t('settings.product_unchecked');
      }

      if (!status.running) {
        return this.$t('settings.product_unavailable');
      }

      if ([true, 'running'].includes(status.status)) {
        return this.$t('settings.product_running');
      }

      return this.$t('settings.product_status_error');
    },

    isAPIButtonDisabled(status) {
      return !status.checked || !status.running || ![true, 'running'].includes(status.status)
    },
  }
}
</script>
<style lang="scss">
.settings-view {
  .settings-body {
    width: 1024px;

    .settings-left {
      width: 400px;
      padding-right: 30px;
    }

    .settings-right {
      width: 600px;

      .label {
        font-weight: bold;
        font-size: 0.9rem;
      }

      .text-field-label {
        margin-bottom: 3px;
      }

      .radio-group-label {
        margin-bottom: -12px;
      }
    }
  }
}
</style>
